import jwtDecode from "jwt-decode";
import CustomerProject from "./customerProject";
import { LoginTypeEnum } from "@/apiManager/login/loginData";

class AccessToken
{
    static Get(): string
    {
        try
        {
            return localStorage.getItem((process.env?.VUE_APP_LICENSE ?? "") + "_access_token") ?? "";
        }
        catch
        {
            return "";
        }
    }

    static GetType(): LoginTypeEnum
    {
        try
        {
            let tokenEncoded: string = localStorage.getItem((process.env?.VUE_APP_LICENSE ?? "") + "_access_token") ?? "";
            if (tokenEncoded.length > 0)
            {
                let tokenDecoded: any = jwtDecode(tokenEncoded);
                return tokenDecoded.type ?? LoginTypeEnum.None;
            }
            return LoginTypeEnum.None;
        }
        catch
        {
            return LoginTypeEnum.None;
        }
    }

    static GetEmail(): string
    {
        try
        {
            let tokenEncoded: string = localStorage.getItem("_access_token") ?? "";
            if (tokenEncoded.length > 0)
            {
                let tokenDecoded: any = jwtDecode(tokenEncoded);
                return tokenDecoded.email ?? "";
            }
            return "";
        }
        catch
        {
            return "";
        }
    }

    // static GetRefreshToken()
    // {
    //     return localStorage.getItem("refresh_token") ?? "";
    // }

    static GetExpiration(): number
    {
        try
        {
            let tokenEncoded: string = localStorage.getItem((process.env?.VUE_APP_LICENSE ?? "") + "_access_token") ?? "";
            if (tokenEncoded.length > 0)
            {
                let tokenDecoded: any = jwtDecode(tokenEncoded);
                return tokenDecoded.exp ?? -1;
            }
            return -1;
        }
        catch
        {
            return -1;
        }

    }

    static isNotExpired(): boolean
    {
        return (Date.now() < AccessToken.GetExpiration() * 1000);
    }

    // static Set(p_AccessToken: string, p_RefreshToken: string)
    // {
    //     AccessToken.SetAccessToken(p_AccessToken);
    //     AccessToken.SetRefreshToken(p_RefreshToken);
    // }

    static Set(p_AccessToken: string)
    {
        try
        {
            localStorage.setItem((process.env?.VUE_APP_LICENSE ?? "") + "_access_token", p_AccessToken);
        }
        catch
        {
        }
    }

    // static SetRefreshToken(p_RefreshToken: string)
    // {
    //     localStorage.setItem("refresh_token", p_RefreshToken);
    // }

    static delete()
    {
        try
        {
            localStorage.removeItem((process.env?.VUE_APP_LICENSE ?? "") + "_access_token");
        }
        catch
        {

        }

        try
        {
            CustomerProject.delete();
        }
        catch
        {

        }
    }

    static IsSupported(): boolean
    {
        let test = "test";
        try
        {
            localStorage.setItem(test, test);
            localStorage.removeItem(test);
            return true;
        }
        catch(e)
        {
            return false;
        }
    }
}

export default AccessToken;