import { Module } from "vuex";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { RootState } from "@/store/types";
import ProductState from "./types";
import ProductDetailSelection from "@/models/productDetail/productDetailSelection";
import { ProductDetailModel } from "@/apiManager/product/productData";

export const state: ProductState =
{
    productGroups: [],
    productGroupsIsLoading: false,
    productDetail: new ProductDetailModel(),
    productDetailIsLoading: false,
    productDates: [],
    productDatesIsLoading: false,
    productDetailSelection: new ProductDetailSelection()
};

const namespaced: boolean = false;

export const productState: Module<ProductState, RootState> = {
    namespaced,
    state,
    mutations,
    actions
};