import Vue from "vue";
import { ActionTree } from "vuex";
import ProductState from "./types";
import { RootState } from "@/store/types";
import ActionsEnum from "@/store/storeEnum/actionsEnum";
import ProductPayload from "./models";
import { ProductGroupListModel } from "@/apiManager/productGroup/productGroupData";
import { ProductDateModel, ProductDateQuery, ProductDetailModel, ProductDetailQuery } from "@/apiManager/product/productData";

export const actions: ActionTree<ProductState, RootState> =
{
    async [ActionsEnum.FetchProducts]({ commit, state }, p_Payload: ProductPayload.Actions.FetchProducts)
    {
        if (!state.productGroupsIsLoading)
        {
            try
            {
                commit(new ProductPayload.Mutations.SetProductGroupsIsLoading(true));

                let data: ProductGroupListModel[] = await Vue.$apiManager.productGroup.listGroupAsync();

                commit(new ProductPayload.Mutations.SetProductGroups(data));

                return data;
            }
            catch (ex)
            {
                return Promise.reject(ex);
            }
            finally
            {
                commit(new ProductPayload.Mutations.SetProductGroupsIsLoading(false));
            }
        }

    },
    async [ActionsEnum.FetchProductDetail]({ commit }, p_Payload: ProductPayload.Actions.FetchProductDetail)
    {
        try
        {
            commit(new ProductPayload.Mutations.SetProductDetailIsLoading(true));
            commit(new ProductPayload.Mutations.SetProductDetail(new ProductDetailModel()));

            let query: ProductDetailQuery = new ProductDetailQuery(p_Payload.productCode, p_Payload.dateFrom, p_Payload.dateTo, p_Payload.dateCode);
            let data: ProductDetailModel = await Vue.$apiManager.product.getDetailAsync(query);

            commit(new ProductPayload.Mutations.SetProductDetail(data));
            return data;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
        finally
        {
            commit(new ProductPayload.Mutations.SetProductDetailIsLoading(false));
        }
    },
    async [ActionsEnum.FetchProductDates]({ commit }, p_Payload: ProductPayload.Actions.FetchProductDates)
    {
        try
        {
            commit(new ProductPayload.Mutations.SetProductDatesIsLoading(true));
            commit(new ProductPayload.Mutations.SetProductDates([]));

            let query: ProductDateQuery = new ProductDateQuery(p_Payload.productCode);
            let data: ProductDateModel[] = await Vue.$apiManager.product.listDateAsync(query);

            let index = 1;
            data.forEach((productDate) =>
            {
                productDate.uniqueID = index;
                index++;
            });

            commit(new ProductPayload.Mutations.SetProductDates(data));
            return data;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
        finally
        {
            commit(new ProductPayload.Mutations.SetProductDatesIsLoading(false));
        }
    }
};