
    import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
    import ModalInformativePayload from "@/store/modules/modalInformative/models";

    @Component
    export default class ModalInformative extends Vue
    {
        get display(): boolean
        {
            return this.$tStore.state.modalInformativeState.display;
        }
        set display(value: boolean)
        {
            this.$tStore.commit(
                new ModalInformativePayload.Mutations.HideModalInformative()
            );
        }

        get getTitle(): string
        {
            return this.$tStore.state.modalInformativeState.title;
        }

        get getMessage(): string
        {
            return this.$tStore.state.modalInformativeState.message;
        }

        get getTheme(): string
        {
            return this.$tStore.state.configState.setting.license.theme;
        }

        onEmitOk()
        {
            this.$emit("ok");
        }
    }

