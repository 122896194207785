
    import { Component, Mixins } from "vue-property-decorator";
    import BeforeRouteEnterMixin from "@/mixins/beforeRouteEnterMixin";
    import Slider from "./Slider.vue";
    import { RezToursLanguageEnum } from "@/apiManager/_common/enums";

    @Component
    export default class Home extends Mixins(BeforeRouteEnterMixin)
    {
        get components()
        {
            let components =
                {
                    "slider": Slider
                };

            return components;
        }

        get getHomeMessage(): string
        {
            return this.translateValue(this.$tStore.state.configState.setting.agency.homeMessage);
        }
    }
