//#region Query

export class PaymentRedirectReservationQuery
{
    reservation: string = "";
    token: string = "";
    amount: number = 0;
    isDeposit: boolean = false;
    clientName: string = "";
    lang: string = "EN";
}

export class PaymentRedirectInvoiceQuery
{
    invoice: string = "";
    amount: number = 0;
    clientName: string = "";
    lang: string = "EN";
}

export class PaymentCancelBody
{
    reservation: string = "";
    token: string = "";
    index: number = 0;
}

//#endregion

//#region Model

export class PaymentURLModel
{
    url: string = "";
}

//#endregion