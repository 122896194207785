import { AxiosInstance, AxiosRequestConfig } from "axios";
import ApiController from "../_common/apiController";
import { ProductDateModel, ProductDateQuery, ProductDetailImageModel, ProductDetailModel, ProductDetailQuery, ProductImageQuery, ProductOptionModel, ProductOptionQuery } from "./productData";

class ProductController extends ApiController
{
    constructor(p_Axios: AxiosInstance)
    {
        super(p_Axios, "product", "1.0");
    }

    public async getDetailAsync(p_Query: ProductDetailQuery): Promise<ProductDetailModel>
    {
        try
        {
            let config: AxiosRequestConfig = {
                params: p_Query
            };

            const request = await this.$axios.get<ProductDetailModel>(`${ this.$controllerUrl }/${ p_Query.productCode }`, config);
            return request.data;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }

    public getFlyer(p_ProductCode: string, p_FlyerName: string, p_Language: string)
    {
        let path = `${ window.location.protocol }//${ window.location.host }/server/api/v1.1/flyer/${ p_ProductCode }/${ p_FlyerName }?lang=${ p_Language }`;
        window.open(path, "_blank");
    }

    public async listDateAsync(p_Query: ProductDateQuery): Promise<ProductDateModel[]>
    {
        try
        {
            const request = await this.$axios.get<ProductDateModel[]>(`${ this.$controllerUrl }/${ p_Query.productCode }/dates`);
            return request.data;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }

    public async listImageAsync(p_Query: ProductImageQuery): Promise<ProductDetailImageModel[]>
    {
        try
        {
            const request = await this.$axios.get<ProductDetailImageModel[]>(`${ this.$controllerUrl }/${ p_Query.productCode }/images`);
            return request.data;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }

    public async listOptionAsync(p_Query: ProductOptionQuery): Promise<ProductOptionModel[]>
    {
        try
        {
            let config: AxiosRequestConfig = {
                params: p_Query
            };

            const request = await this.$axios.get<ProductOptionModel[]>(`${ this.$controllerUrl }/${ p_Query.productCode }/options`, config);
            return request.data;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }
}

export default ProductController;