import { Module } from "vuex";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { getters } from "./getters";
import { RootState } from "@/store/types";
import ReservationState from "./types";
import { ReservationModel } from "@/apiManager/reservation/reservationData";
import { InvoiceCreateModel } from "@/apiManager/invoice/invoiceData";

export const state: ReservationState =
{
    detail: new ReservationModel(),
    backgroundImagePath: "",
    redirect: false,
    invoice: new InvoiceCreateModel(),
    showModalCancelReservation: false,
    layoutLoading: false,
    intervalTimer: 0,
    modalCancelReservationLogOut: false,
    modalCancelReservationRedirect: ""
};

const namespaced: boolean = false;

export const reservationState: Module<ReservationState, RootState> = {
    namespaced,
    state,
    mutations,
    actions,
    getters
};