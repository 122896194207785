import { AxiosInstance } from "axios";

class ApiController
{
    protected readonly $axios: AxiosInstance;
    protected readonly $controllerUrl: string;

    constructor(p_Axios: AxiosInstance, p_ControllerUrl: string, p_Version: string)
    {
        this.$axios = p_Axios;
        this.$controllerUrl = `${ p_ControllerUrl }/v${ p_Version }`;
    }
}

export default ApiController;