import { AxiosInstance, AxiosRequestConfig } from "axios";
import ApiController from "../_common/apiController";
import { ReservationBody, ReservationCreateModel, ReservationModel, ReservationQuery } from "./reservationData";

class ProductController extends ApiController
{
    constructor(p_Axios: AxiosInstance)
    {
        super(p_Axios, "reservation", "1.0");
    }

    public async getDetailAsync(p_Query: ReservationQuery): Promise<ReservationModel>
    {
        try
        {
            let config: AxiosRequestConfig = {
                params: p_Query
            };

            const request = await this.$axios.get<ReservationModel>(`${ this.$controllerUrl }/${ p_Query.reservationCode }`, config);
            return request.data;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }

    public async getPreviewAsync(p_Body: ReservationBody): Promise<ReservationModel>
    {
        try
        {
            const request = await this.$axios.post<ReservationModel>(`${ this.$controllerUrl }/preview`, p_Body);
            return request.data;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }

    public async createAsync(p_Body: ReservationBody): Promise<ReservationCreateModel>
    {
        try
        {
            const request = await this.$axios.post<ReservationCreateModel>(this.$controllerUrl, p_Body);
            return request.data;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }

    public async deleteAsync(p_Query: ReservationQuery): Promise<boolean>
    {
        try
        {
            let config: AxiosRequestConfig = {
                params: p_Query
            };

            await this.$axios.delete(`${ this.$controllerUrl }/${ p_Query.reservationCode }`, config);
            return true;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }
}

export default ProductController;