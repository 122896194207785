import LanguageModel from "../_common/languageModel";

//#region Model

export class ProductGroupListModel
{
    code: number = 0;

    description: LanguageModel = new LanguageModel();
    listProduct: ProductGroupListProductModel[] = [];
    name: LanguageModel = new LanguageModel();
    pathImage: string = "";
}

export class ProductGroupListProductModel
{
    code: string = "";

    name: LanguageModel = new LanguageModel();
    pathImage: string = "";
    pricing: ProductGroupListPricingModel = new ProductGroupListPricingModel();
}

export class ProductGroupListPricingModel
{
    currency: string = "";
    dateDepart: string = "";
    dateReturn: string = "";
    duration: number = 0;
    pricingCategoryName: LanguageModel = new LanguageModel();
    pricingClassName: LanguageModel = new LanguageModel();
    price: number = 0;
    productPriceDate: number = 0;
    taxes: number = 0;
    total: number = 0;
}

//#endregion