import Vue from "vue";
import Component from "vue-class-component";
import { CurrencyPositionEnum, RezToursLanguageEnum } from "@/apiManager/_common/enums";
import LanguageModel from "@/apiManager/_common/languageModel";

@Component
export default class LanguageMixin extends Vue
{

    // get getConfigLanguageValues(): ConfigRezToursLanguageValuesModel
    // {
    //     return this.$tStore.getters.getConfigLanguage.values;
    // }

    get getUseMainLanguage(): boolean
    {
        return this.$tStore.state.configState.useMainLanguage;
    }

    get g_Language(): string
    {
        return (this.getUseMainLanguage ? this.$tStore.state.configState.setting.agency.lang1.code : this.$tStore.state.configState.setting.agency.lang2.code).toUpperCase();
    }

    translateValue(p_Value: LanguageModel): string
    {
        return (this.getUseMainLanguage ? p_Value.lang1 : p_Value.lang2);
    }

    i18n(p_key: RezToursLanguageEnum, p_Param?: string): string
    {
        if (p_key)
        {
            let value: string = p_key.charAt(0).toUpperCase() + p_key.slice(1);
            if (p_Param)
            {
                let returnValue = this.$tStore.getters.getConfigLanguage.values[value] || "";
                return returnValue.replace("{0}", p_Param);
            }
            else
            {
                return this.$tStore.getters.getConfigLanguage.values[value] || "";
            }
        }
        return "";
    }

    get getRezToursLanguageEnum(): typeof RezToursLanguageEnum
    {
        return RezToursLanguageEnum;
    }

    get getMonthsNames(): string[]
    {
        return this.$tStore.getters.getConfigLanguage.months_Names;
    }

    get getMonthsAbbr(): string[]
    {
        return this.$tStore.getters.getConfigLanguage.months_Abbr;
    }

    get getDaysNames(): string[]
    {
        return this.$tStore.getters.getConfigLanguage.days_Names;
    }

    get getDaysAbbr(): string[]
    {
        return this.$tStore.getters.getConfigLanguage.days_Abbr;
    }

    get getIsCurrencyPositionLeft(): boolean
    {
        return this.$tStore.getters.getConfigLanguage.currencyPosition === CurrencyPositionEnum.Left;
    }

}