import PluginModel from "./pluginModel";
import Vue from "vue";

import TStoreMixin from "@/mixins/tStoreMixin";

class TStoreMixinPlugin implements PluginModel
{
    install(p_Vue: any, options: any): void
    {
        p_Vue.mixin(TStoreMixin);
    }
}

Vue.use(new TStoreMixinPlugin());

export default TStoreMixinPlugin;