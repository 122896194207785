import PluginModel from "./pluginModel";
import Vue from "vue";

import LanguageMixin from "@/mixins/languageMixin";

class languageMixinPlugin implements PluginModel
{
    install(p_Vue: any, options: any): void
    {
        p_Vue.mixin(LanguageMixin);
    }
}

Vue.use(new languageMixinPlugin());

export default languageMixinPlugin;