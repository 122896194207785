
    import { Component, Prop, Vue } from "vue-property-decorator";
    import { ProductGroupListModel } from "@/apiManager/productGroup/productGroupData";
    import { SettingModel } from "@/apiManager/setting/settingData";

    @Component
    export default class ProductGroupLabel extends Vue
    {

        @Prop({ type: Object, required: true }) private readonly productGroup!: ProductGroupListModel;
        @Prop({ type: Number, required: true }) private readonly index!: number;
        @Prop({ type: Boolean, required: true }) private readonly isSelected!: boolean;

        onClickProductGroupLabel()
        {
            this.$emit("click-product-group-label", this.productGroup, this.index);
            if (!this.isSelected)
            {
                let container = document.querySelector("#productGroupLabel-" + this.index);
                if (container)
                {
                    container.scrollIntoView({ behavior: "smooth", block: "start" });
                }
            }
        }

        get getSetting(): SettingModel
        {
            return this.$tStore.state.configState.setting;
        }

        get getProductGroupAppearance(): number
        {
            return this.$tStore.state.configState.setting.license.productGroupAppearance;
        }

        get getImageBackground(): string
        {
            if (this.productGroup.pathImage.length > 0)
            {
                return "background-image: url(" + this.getSetting.license.urlWebSiteServer + this.productGroup.pathImage + ");";
            }
            else
            {
                return "";
            }
        }
    }
