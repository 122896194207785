
    import { Component, Vue } from "vue-property-decorator";
    import BannerHeaderMobile from "@/app/common/views/AppLayout.vue/BannerHeaderMobile.vue";
    import BannerHeaderDesktop from "@/app/common/views/AppLayout.vue/BannerHeaderDesktop.vue";

    @Component
    export default class BannerHeader extends Vue
    {
        get components()
        {
            let components = {
                "banner-header-mobile": BannerHeaderMobile,
                "banner-header-desktop": BannerHeaderDesktop
            };

            return components;
        }
    }
