
    import { Component, Vue, Watch } from "vue-property-decorator";
    import AuthenticationModalPayload from "@/store/modules/authenticationModal/models";
    import { AxiosError } from "axios";
    import CustomerProject from "@/models/customerProject";
    import { UserCustomerModel, UserCustomerProjectModel } from "@/apiManager/user/userData";
    import { ErrorServerCodeEnum, RezToursLanguageEnum } from "@/apiManager/_common/enums";

    @Component
    export default class CustomerProjectsModal extends Vue
    {
        isLoading: boolean = false;
        listCustomerProjects: UserCustomerProjectModel[] = [];
        customerProjectSelected: string = "";
        hasErrorServer: boolean = false;
        errorServerMessage: string = "";

        get display(): boolean
        {
            return this.$tStore.state.authenticationModal.isCustomerProjectsModalVisible;
        }

        get getTheme(): string
        {
            return this.$tStore.state.configState.setting.license.theme;
        }

        set display(value: boolean)
        {
            this.$tStore.commit(
                new AuthenticationModalPayload.Mutations.SetVisibleCustomerProjectsModal(value)
            );
        }

        async loadingCustomerProjectsAsync()
        {
            this.isLoading = true;

            try
            {
                this.listCustomerProjects = this.$tStore.state.authenticationModal.listCustomerProjects;
            }
            catch (ex)
			{
				switch((ex as AxiosError)?.response?.data?.codeError)
				{
                    case ErrorServerCodeEnum.CommunicationErrorWithPCVWeb:
                        this.errorServerMessage = this.i18n(RezToursLanguageEnum.errorCommPCVWeb);
						break;
                    default:
                        this.errorServerMessage = this.i18n(RezToursLanguageEnum.serverErrorMsg);
                }
                this.hasErrorServer = true;
			}
            finally
            {
                this.listCustomerProjects.unshift({ code: "" });
                this.isLoading = false;
            }
        }

        async onClickSelectCustomerProject(): Promise<void>
        {
            CustomerProject.Set(this.customerProjectSelected);
            this.display = false;
            this.customerProjectSelected = "";
            let customer: UserCustomerModel = await Vue.$apiManager.user.getCustomerAsync();
            this.$tStore.commit(new AuthenticationModalPayload.Mutations.HideAuthenticationModal());
            this.$tStore.commit(new AuthenticationModalPayload.Mutations.SetWelcomeComponent(true, `${ customer.name }`));
            setTimeout(() =>
                {
                    this.$tStore.commit(new AuthenticationModalPayload.Mutations.SetWelcomeComponent(false));
                }, 5000);
        }

        @Watch("display")
        onWatchDisplay()
        {
            if (this.display)
            {
                this.loadingCustomerProjectsAsync();
            }
        }
    }
