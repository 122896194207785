
    import { Component, Prop, Vue } from "vue-property-decorator";
    import RoutesEnum from "@/router/routesEnum";
    import moment, { Moment } from "moment";
    import FormatMoney from "@/app/common/components/FormatMoney.vue";
    import LanguageModel from "@/apiManager/_common/languageModel";
    import { SettingModel } from "@/apiManager/setting/settingData";
    import { ProductGroupListProductModel } from "@/apiManager/productGroup/productGroupData";

    @Component
    export default class ProductItem extends Vue
    {
        @Prop({ type: Object, required: true }) private readonly product!: ProductGroupListProductModel;
        @Prop({ type: Object, required: true }) private readonly productGroupName!: LanguageModel;
        @Prop({ type: Number, required: true }) private readonly productGroupCode!: number;

        get getSetting(): SettingModel
        {
            return this.$tStore.state.configState.setting;
        }

        get getUrlWebSiteServer(): string
        {
            return this.getSetting.license.urlWebSiteServer;
        }

        get getMonthsNames(): string[]
        {
            return this.$tStore.getters.getConfigLanguage.months_Abbr;
        }

        get getDaysNames(): string[]
        {
            return this.$tStore.getters.getConfigLanguage.days_Abbr;
        }

        get getDepartMoment(): Moment
        {
            return moment(this.product.pricing.dateDepart);
        }

        get getReturnMoment(): Moment
        {
            return moment(this.product.pricing.dateReturn);
        }

        get getSeparator(): string
        {
            return (this.getUseMainLanguage ? " " : ",");
        }

        onProductClick()
        {
            this.$router.push(
                {
                    name: RoutesEnum.ProductDetail,
                    query:
                        {
                            prod: this.product.code,
                            prodPDat: this.product.pricing.productPriceDate.toString(),
                            dtDep: moment(this.product.pricing.dateDepart).format("YYYY-MM-DD"),
                            dtRet: moment(this.product.pricing.dateReturn).format("YYYY-MM-DD")
                        },
                    params:
                    {
                        productGroupSelected: this.productGroupCode.toString()
                    }
                }
            );
        }

        get getImageBackground()
        {
            if (this.product.pathImage.length > 0)
            {
                return "background-image: url(" + this.getUrlWebSiteServer + this.product.pathImage + ");";
            }
            else
            {
                return "";
            }
        }

        onClickOtherDates()
        {
            this.$emit("open-popup-dates", this.product);
        }

        get components()
        {
            let components =
                {
                    "format-money": FormatMoney
                };
            return components;
        }
    }
