import { Component, Vue } from "vue-property-decorator";
import { Route, RawLocation } from "vue-router";
import ReservationTokenModel from "@/models/reservationTokenModel";
import RoutesEnum, { RoutesReservationEnum } from "@/router/routesEnum";

@Component
export default class BeforeRouteEnterMixin extends Vue
{

    beforeRouteEnter(p_To: Route, p_From: Route, p_Next: (to?: RawLocation | false) => void): any
    {
        let token = new ReservationTokenModel();
        if (ReservationTokenModel.IsSupported() && token.isReservationInProgress())
        {
            if (p_To.name && Object.values(RoutesReservationEnum).includes(p_To.name))
            {
                p_Next();
            }
            else
            {
                p_Next({
                    name: RoutesEnum.Redirect,
                    replace: true
                });
            }
        }
        else
        {
            if (p_To.name && (Object.values(RoutesReservationEnum).includes(p_To.name) && p_To.name !== RoutesEnum.TravelBag))
            {
                p_Next({
                    name: RoutesEnum.Package,
                    replace: true
                });
            }
            else
            {
                p_Next();
            }
        }
    }

}