
    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component
    export default class FormatMoney extends Vue
    {
        @Prop({ type: Number, required: true }) private readonly amount!: number;
        @Prop({ type: String }) private readonly currency!: string;
        @Prop({ type: Boolean }) private readonly onlyCurrency!: boolean;
        @Prop({ type: Boolean }) private readonly currencyColor!: boolean;
        @Prop({ type: Boolean }) private readonly showCents!: boolean;

        get getAmountWithoutDecimal(): string
        {
            let value = this.showCents ? Math.trunc(this.amount) : Math.ceil(this.amount);
            return value.toString();
        }

        get getAmountDecimal(): string
        {
            let valueSplited = this.amount.toFixed(2).split(".");

            if (valueSplited.length === 2 && !isNaN(parseInt(valueSplited[1])))
            {
                return valueSplited[1];
            }
            else
            {
                return "00";
            }
        }

        get getSeparator(): string
        {
            return (this.getUseMainLanguage ? " " : ",");
        }
    }
