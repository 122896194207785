
    import { Component, Prop, Vue } from "vue-property-decorator";
    import moment, { Moment } from "moment";
    import RoutesEnum from "@/router/routesEnum";
    import FormatMoney from "@/app/common/components/FormatMoney.vue";
    import { ProductDateModel, ProductDetailModel } from "@/apiManager/product/productData";

    @Component
    export default class PopupProductDatesItem extends Vue
    {
        @Prop({ type: String, required: true }) private readonly productCode!: string;
        @Prop({ type: Object, required: true }) private readonly productDate!: ProductDateModel;

        get components()
        {
            let components =
            {
                "format-money": FormatMoney
            };

            return components;
        }

        get getMonthsNames(): string[]
        {
            return this.$tStore.getters.getConfigLanguage.months_Abbr;
        }

        get getDaysNames(): string[]
        {
            return this.$tStore.getters.getConfigLanguage.days_Abbr;
        }

        get getDateDepart(): Moment
        {
            return moment(this.productDate.dateDepart);
        }

        get getDateReturn(): Moment
        {
            return moment(this.productDate.dateReturn);
        }

        onProductClick()
        {
            this.$router.push(
                {
                    name: RoutesEnum.ProductDetail,
                    query:
                        {
                            prod: this.productCode,
                            prodPDat: this.productDate.productPriceDateCode.toString(),
                            dtDep: this.getDateDepart.format("YYYY-MM-DD"),
                            dtRet: this.getDateReturn.format("YYYY-MM-DD")
                        }
                }
            );
            this.$emit("close");
        }
    }
