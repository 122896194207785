import Vue from "vue";

import
{
    FontAwesomeIcon,
    FontAwesomeLayers,
    FontAwesomeLayersText
} from "@fortawesome/vue-fontawesome";

import { library } from "@fortawesome/fontawesome-svg-core";

//Solid
import
{
    faMapMarkerAlt as faMapMarkerAltSolid,
    faCircle,
    faClipboardCheck as faClipboardSolid,
    faPhoneAlt as faPhoneAltSolid,
    faMousePointer as faMousePointerSolid,
    faAt as faAtSolid,
    faLocationArrow as faLocationArrowSolid,
    faCalendar as faCalendarSolid,
    faSpinner,
    faCalendarPlus as faCalendarPlusSolid,
    faAngleDown as faAngleDownSolid,
    faChevronRight,
    faChevronLeft,
    faTags as faTagsSolid,
    faUser as faUserSolid,
    faMoneyCheckAlt as faMoneyCheckAltSolid,
    faCheckCircle as faCheckCircleSolid,
    faStopwatch as faStopwatchSolid,
    faUserCircle as faUserCircleSolid,
    faBed as faBedSolid,
    faTimesCircle as faTimesCircleSolid,
    faArrowToBottom as faArrowToBottomSolid,
    faSun as faSunSolid,
    faMoon as faMoonSolid,
    faCalendarStar as faCalendarStarSolid,
    faSuitcase as faSuitcaseSolid,
    faExclamationCircle as faExclamationCircleSolid,
    faArrowAltCircleRight as faArrowAltCircleRightSolid,
    faArrowAltCircleLeft as faArrowAltCircleRightLeftSolid,
    faPennant as faPennantSolid,
    faCheck as faCheckSolid,
    faStreetView as faStreetViewSolid,
    faSortDown as faSortDownSolid,
    faInfoCircle as faInfoCircleSolid,
    faChartPie as faChartPieSolid,
    faChevronDown as faChevronDownSolid,
    faChevronUp as faChevronUpSolid,
    faEye as faEyeRegular,
    faCaretDown as faCaretDownRegular,
    faCaretRight as faCaretRightRegular

} from "@fortawesome/pro-solid-svg-icons";

//Regular
import
{
    faMapMarkerAlt as faMapMarkerAltRegular,
    faChevronCircleDown as faChevronCircleDownregular,
    faArrowCircleRight as faArrowCircleRightRegular,
    faExclamationCircle as faExclamationCircleRelugar,
    faTimesCircle as faTimesCircleregular,
    faHeart as faHeartRegular,
    faPrint as faPrintRegular,
    faArrowToBottom as faArrowToBottomRegular,
    faListAlt as faListAltRegular

} from "@fortawesome/pro-regular-svg-icons";

//Light
import
{
    faChevronCircleDown,
    faArrowCircleRight as faArrowCircleRightLight,
    faTimesCircle as faTimesCircleLight,
    faExclamationCircle as faExclamationCircleLight,
    faChevronRight as faChevronRightLight,
    faArrowCircleLeft as faArrowCircleLeftLight,
    faMountain as faMountainLight,
    faPlane as faPlaneLight,
    faShip as faShipLight,
    faUmbrellaBeach as faUmbrellaBeachLight,
    faCreditCard as faCreditCardLight,
    faCheckCircle as faCheckCircleLight,
    faCocktail as faCocktailLight,
    faPlaneDeparture as faPlaneDepartureLight,
    faSuitcaseRolling as faSuitcaseRollingLight,
    faSun as faSunLight,
    faArrowToBottom as faArrowToBottomLight,
    faFileInvoice as faFileInvoiceLight,
    faHome as faHomeLight,
    faMoon as faMoonLight,
    faDollyFlatbedEmpty as faDollyFlatbedEmptyLight,
    faHeart as faHeartLight,
    faUserCircle as faUserCircleLight,
    faHomeLg as faHomeLgLight,
    faMarker as faMarkerLight,
    faPassport as faPassportLight,
    faSignOutAlt as faSignOutAltLight,
    faBars as faBarsLight,
    faUserAlt as faUserAltLight,
    faWallet as faWalletLight,
    faSmileWink as faSmileWinkLight,
    faCalendarEdit as faCalendarEditLight,
    faCog as facogLight,
    faEye as faEyeLight,
    faChevronDown as faChevronDownLight,
    faChevronUp as faChevronUpLight

} from "@fortawesome/pro-light-svg-icons";

//Duotone
import
{
    faMapMarkerAlt as faMapMarkerAltDuo,
    faSpinnerThird as faSpinnerThirdDuo
} from "@fortawesome/pro-duotone-svg-icons";

import
{

} from "@fortawesome/free-brands-svg-icons";

Vue.component("pcv-icon", FontAwesomeIcon);
Vue.component("pcv-layers", FontAwesomeLayers);
Vue.component("pcv-layers-text", FontAwesomeLayersText);

library.add(
    faMapMarkerAltSolid,
    faMapMarkerAltRegular,
    faMapMarkerAltDuo,
    faCircle,
    faChevronCircleDown,
    faChevronCircleDownregular,
    faClipboardSolid,
    faPhoneAltSolid,
    faMousePointerSolid,
    faAtSolid,
    faArrowCircleRightLight,
    faArrowCircleRightRegular,
    faLocationArrowSolid,
    faCalendarSolid,
    faSpinner,
    faCalendarPlusSolid,
    faAngleDownSolid,
    faTimesCircleLight,
    faExclamationCircleLight,
    faChevronLeft,
    faChevronRight,
    faTagsSolid,
    faUserSolid,
    faMoneyCheckAltSolid,
    faCheckCircleSolid,
    faStopwatchSolid,
    faChevronRightLight,
    faArrowCircleLeftLight,
    faUserCircleSolid,
    faBedSolid,
    faMountainLight,
    faPlaneLight,
    faShipLight,
    faUmbrellaBeachLight,
    faCreditCardLight,
    faCheckCircleLight,
    faExclamationCircleRelugar,
    faTimesCircleregular,
    faTimesCircleSolid,
    faCocktailLight,
    faPlaneDepartureLight,
    faSuitcaseRollingLight,
    faSunLight,
    faSpinnerThirdDuo,
    faArrowToBottomLight,
    faFileInvoiceLight,
    faArrowToBottomSolid,
    faHomeLight,
    faSunSolid,
    faMoonSolid,
    faMoonLight,
    faCalendarStarSolid,
    faSuitcaseSolid,
    faExclamationCircleSolid,
    faArrowAltCircleRightSolid,
    faArrowAltCircleRightLeftSolid,
    faDollyFlatbedEmptyLight,
    faPennantSolid,
    faHeartLight,
    faUserCircleLight,
    faHeartRegular,
    faTimesCircleLight,
    faCheckCircleSolid,
    faCheckSolid,
    faHomeLgLight,
    faMarkerLight,
    faStreetViewSolid,
    faPassportLight,
    faSignOutAltLight,
    faBarsLight,
    faUserAltLight,
    faSortDownSolid,
    faInfoCircleSolid,
    faWalletLight,
    faSmileWinkLight,
    faChartPieSolid,
    faChevronDownSolid,
    faChevronUpSolid,
    faCalendarEditLight,
    facogLight,
    faPrintRegular,
    faArrowToBottomRegular,
    faEyeRegular,
    faEyeLight,
    faChevronDownLight,
    faChevronUpLight,
    faListAltRegular,
    faCaretDownRegular,
    faCaretRightRegular
);