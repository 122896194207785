import { MutationTree } from "vuex";
import ModalInformativeState from "./types";
import MutationsEnum from "@/store/storeEnum/mutationsEnum";
import ModalInformativePayload from "./models";

export const mutations: MutationTree<ModalInformativeState> =
{
    [MutationsEnum.DisplayModalInformative](p_State: ModalInformativeState, p_Payload: ModalInformativePayload.Mutations.DisplayModalInformative)
    {
        p_State.title = p_Payload.title;
        p_State.message = p_Payload.message;
        p_State.display = true;
    },

    [MutationsEnum.HideModalInformative](p_State: ModalInformativeState, p_Payload: ModalInformativePayload.Mutations.HideModalInformative)
    {
        p_State.display = false;
    }
};