import { AxiosInstance, AxiosRequestConfig } from "axios";
import ApiController from "../_common/apiController";
import { InvoiceCreateBody, InvoiceCreateModel, InvoiceModel, InvoicePrintURLModel, InvoicePrintURLQuery, InvoiceQuery, InvoiceSummaryModel } from "./invoiceData";

class InvoiceController extends ApiController
{
    constructor(p_Axios: AxiosInstance)
    {
        super(p_Axios, "invoice", "1.0");
    }

    public async getDetailAsync(p_Query: InvoiceQuery): Promise<InvoiceModel>
    {
        try
        {
            let config: AxiosRequestConfig = {
                params: p_Query
            };

            const request = await this.$axios.get<InvoiceModel>(`${ this.$controllerUrl }/byfile`, config);
            return request.data;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }

    public async listAsync(): Promise<InvoiceSummaryModel[]>
    {
        try
        {
            const request = await this.$axios.get<InvoiceSummaryModel[]>(`${ this.$controllerUrl }`);
            return request.data;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }

    public async createAsync(p_Body: InvoiceCreateBody): Promise<InvoiceCreateModel>
    {
        try
        {
            const request = await this.$axios.post<InvoiceCreateModel>(this.$controllerUrl, p_Body);
            return request.data;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }

    public async getPrintURLAsync(p_Query: InvoicePrintURLQuery): Promise<InvoicePrintURLModel>
    {
        try
        {
            let config: AxiosRequestConfig = {
                params: p_Query
            };

            const request = await this.$axios.get<InvoicePrintURLModel>(`${ this.$controllerUrl }/${ p_Query.invoice }/printurl`, config);
            return request.data;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }
}

export default InvoiceController;