import { AxiosInstance, AxiosRequestConfig } from "axios";
import ApiController from "../_common/apiController";
import { SettingModel, SettingTermsModel, SettingTermsQuery } from "./settingData";

class SettingController extends ApiController
{
    constructor(p_Axios: AxiosInstance)
    {
        super(p_Axios, "setting", "1.0");
    }

    public async GetAsync(): Promise<SettingModel>
    {
        try
        {
            const request = await this.$axios.get<SettingModel>(`${ this.$controllerUrl }/setting`);
            return request.data;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }

    public async getTermsAsync(p_Lang: string): Promise<SettingTermsModel>
    {
        try
        {
            let config: AxiosRequestConfig = {
                params: new SettingTermsQuery(p_Lang)
            };

            const request = await this.$axios.get<SettingTermsModel>(`${ this.$controllerUrl }/terms`, config);
            return request.data;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }
}

export default SettingController;