import { AxiosInstance } from "axios";
import ApiController from "../_common/apiController";
import { LoginAutoLoginBody, LoginRegisterCustomerBody, LoginRegisterPaxBody, LoginSendResetPasswordEmailBody, LoginSetResetPasswordBody, LoginSignInBody, LoginSignInModel } from "./loginData";

class LoginController extends ApiController
{
    constructor(p_Axios: AxiosInstance)
    {
        super(p_Axios, "login", "1.0");
    }

    public async signInAsync(p_Body: LoginSignInBody): Promise<LoginSignInModel>
    {
        try
        {
            const request = await this.$axios.post<LoginSignInModel>(`${ this.$controllerUrl }/signin`, p_Body);
            return request.data;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }

    public async registerPaxAsync(p_Body: LoginRegisterPaxBody): Promise<boolean>
    {
        try
        {
            await this.$axios.post(`${ this.$controllerUrl }/pax`, p_Body);
            return true;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }

    public async registerCustomerAsync(p_Body: LoginRegisterCustomerBody): Promise<boolean>
    {
        try
        {
            await this.$axios.post(`${ this.$controllerUrl }/customer`, p_Body);
            return true;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }

    public async sendResetPasswordEmailAsync(p_Body: LoginSendResetPasswordEmailBody): Promise<boolean>
    {
        try
        {
            await this.$axios.post(`${ this.$controllerUrl }/send-reset-password`, p_Body);
            return true;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }

    public async setResetPasswordAsync(p_Body: LoginSetResetPasswordBody): Promise<boolean>
    {
        try
        {
            await this.$axios.post(`${ this.$controllerUrl }/set-reset-password`, p_Body);
            return true;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }

    public async autoLoginAsync(p_Body: LoginAutoLoginBody): Promise<LoginSignInModel>
    {
        try
        {
            const request = await this.$axios.post<LoginSignInModel>(`${ this.$controllerUrl }/autologin`, p_Body);
            return request.data;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }
}

export default LoginController;