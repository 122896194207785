
    import { Component, Vue } from "vue-property-decorator";
    import RoutesEnum from "@/router/routesEnum";
    import SocialNetworkFooter from "./SocialNetworkFooter.vue";
    import { SettingModel } from "@/apiManager/setting/settingData";

    @Component
    export default class BannerFooter extends Vue
    {
        get components()
        {
            let components = {
                "social-network-footer": SocialNetworkFooter
            };

            return components;
        }

        get getSetting(): SettingModel
        {
            return this.$tStore.state.configState.setting;
        }

        get getAgencyName(): string
        {
            return this.translateValue(this.getSetting.agency.name);
        }

        get getAgencyDetail(): string
        {
            return this.translateValue(this.getSetting.agency.detail);
        }

        get getAgencyTelephone(): string
        {
            return this.translateValue(this.getSetting.agency.telephone);
        }

        get getAgencyWebSite(): string
        {
            return this.translateValue(this.getSetting.agency.webSite);
        }

        get getAgencyAddress(): string
        {
            return this.translateValue(this.getSetting.agency.address);
        }

        get getAgencyEmail(): string
        {
            return this.translateValue(this.getSetting.agency.email);
        }

        get getUrlLogo(): string
        {
            return this.getSetting.license.urlWebSiteServer + this.translateValue(this.getSetting.agency.logo);
        }

        get getHasSocialNetwork(): boolean
        {
            let facebook = this.translateValue(this.getSetting.socialNetwork.urlFacebook);
            let twitter = this.translateValue(this.getSetting.socialNetwork.urlTwitter);
            let instagram = this.translateValue(this.getSetting.socialNetwork.urlInstagram);
            let pinterest = this.translateValue(this.getSetting.socialNetwork.urlPinterest);

            return (
                facebook.length > 0 ||
                twitter.length > 0 ||
                instagram.length > 0 ||
                pinterest.length > 0
            );
        }

        onClickLogo()
        {
            if (this.$route.name !== RoutesEnum.Package)
            {
                this.$router.push({ name: RoutesEnum.Package });
            }
        }

        onSocialNetworkClick(link: string)
        {
            window.open(link, "_blank");
        }
    }
