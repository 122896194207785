import { Payload } from "vuex";
import MutationsEnum from "@/store/storeEnum/mutationsEnum";

namespace ModalInformativePayload
{
    export namespace Mutations
    {
        export class DisplayModalInformative implements Payload
        {
            readonly type: string = MutationsEnum.DisplayModalInformative;
            title: string;
            message: string;

            constructor(p_Title: string, p_Message: string)
            {
                this.title = p_Title;
                this.message = p_Message;
            }
        }

        export class HideModalInformative implements Payload
        {
            readonly type: string = MutationsEnum.HideModalInformative;
        }
    }
}

export default ModalInformativePayload;