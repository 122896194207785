
    import { Component, PropSync, Vue } from "vue-property-decorator";
    import { AxiosError } from "axios";
    import { LoginSendResetPasswordEmailBody } from "@/apiManager/login/loginData";
    import { ErrorServerCodeEnum, RezToursLanguageEnum } from "@/apiManager/_common/enums";

    @Component
    export default class ForgottenPassword extends Vue
    {
        @PropSync("sectionDisplayed", { type: Number }) private syncedSectionDisplayed!: number;

        private email: string = "";
        private hasErrorServer: boolean = false;
        private errorServerMessage: string = "";

        async onClickConfirmation()
        {
            this.hasErrorServer = false;
            try
            {
                let body: LoginSendResetPasswordEmailBody = new LoginSendResetPasswordEmailBody(this.email, this.g_Language);
                await this.$apiManager.login.sendResetPasswordEmailAsync(body);

                this.syncedSectionDisplayed = 2;
            }
            catch(ex)
            {
                switch((ex as AxiosError)?.response?.data?.codeError)
                {
                    case ErrorServerCodeEnum.ResetPasswordEmailInvalid:
                        this.errorServerMessage = this.i18n(RezToursLanguageEnum.errorRecoverPasswordEmailInvalid);
                        break;
                    case ErrorServerCodeEnum.ActionFailed:
                        this.errorServerMessage = this.i18n(RezToursLanguageEnum.errorUnableToSendEmail);
                        break;
                    default:
                        this.errorServerMessage = this.i18n(RezToursLanguageEnum.serverErrorMsg);
                }
                this.hasErrorServer = true;
            }
        }

        onClickReturn()
        {
            this.syncedSectionDisplayed = 0;
        }
    }
