import Vue from "vue";
import Router, { Route, RawLocation } from "vue-router";
import Home from "@/app/product/views/Home.vue";
import ProductList from "@/app/product/views/Home.vue/ProductList.vue";
import RoutesEnum from "./routesEnum";
import AppLayout from "@/app/common/views/AppLayout.vue";

Vue.use(Router);

let theRouter = new Router({
    routes: [
        {
            path: "/",
            component: AppLayout,
            props: (route) =>
            {
                return Object.assign({}, route.query, route.params);
            },
            children:
            [
                {
                    path: "/",
                    component: Home,
                    children:
                    [
                        {
                            path: "",
                            name: RoutesEnum.Package,
                            component: ProductList
                        }
                    ]
                },
                {
                    path: "reservations",
                    component: () =>
                    {
                        return import("@/app/reservation/views/ReservationLayout.vue");
                    },
                    children:
                    [
                        {
                            path: "",
                            redirect: { name: RoutesEnum.TravelBag }
                        },
                        {
                            path: "options",
                            name: RoutesEnum.Options,
                            component: () =>
                            {
                                return import("@/app/reservation/views/Options.vue");
                            },
                            props: (route) =>
                            {
                                return Object.assign({}, route.params);
                            }
                        },
                        {
                            path: "travelBag",
                            name: RoutesEnum.TravelBag,
                            component: () =>
                            {
                                return import("@/app/reservation/views/TravelBag.vue");
                            },
                            props: (route) =>
                            {
                                return Object.assign({}, route.params);
                            }
                        },
                        {
                            path: "passenger",
                            name: RoutesEnum.PaxInfo,
                            component: () =>
                            {
                                return import("@/app/reservation/views/PaxInfo.vue");
                            }
                        },
                        {
                            path: "payment",
                            name: RoutesEnum.Payment,
                            component: () =>
                            {
                                return import("@/app/reservation/views/Payment.vue");
                            }
                        },
                        {
                            path: "redirect",
                            name: RoutesEnum.Redirect,
                            component: () =>
                            {
                                return import("@/app/reservation/views/Redirect.vue");
                            }
                        },
                        {
                            path: "booking",
                            name: RoutesEnum.Booking,
                            component: () =>
                            {
                                return import("@/app/reservation/views/Booking.vue");
                            }
                        }
                    ]
                },
                {
                    path: "/details",
                    name: RoutesEnum.ProductDetail,
                    component: () =>
                    {
                        return import("@/app/product/views/ProductDetail.vue");
                    },
                    props: (route) =>
                    {
                        return Object.assign({}, route.params);
                    }
                },
                {
                    path: "/profile",
                    name: RoutesEnum.Profile,
                    component: () =>
                    {
                        return import("@/app/authentication/views/Profile.vue");
                    }
                },
                {
                    path: "/outstanding-invoice",
                    name: RoutesEnum.OutstandingInvoiceList,
                    component: () =>
                    {
                        return import("@/app/authentication/views/OutstandingInvoiceList.vue");
                    }
                },
                {
                    path: "/outstanding-invoice/:file",
                    name: RoutesEnum.OutstandingInvoiceDetail,
                    component: () =>
                    {
                        return import("@/app/authentication/views/OutstandingInvoiceDetail.vue");
                    },
                    props: true
                },
                {
                    path: "/inscription",
                    name: RoutesEnum.Inscription,
                    component: () =>
                    {
                        return import("@/app/authentication/views/InscriptionPage.vue");
                    }
                },
                {
                    path: "/register/customer",
                    name: RoutesEnum.RegisterCustomer,
                    props: true,
                    component: () =>
                    {
                        return import("@/app/authentication/views/RegisterCustomer.vue");
                    }
                },
                {
                    path: "/interface",
                    name: RoutesEnum.Interface,
                    component: () =>
                    {
                        return import("@/app/authentication/views/Interface.vue");
                    }
                },
                {
                    path: "/reset-password",
                    name: RoutesEnum.ResetPassword,
                    component: () =>
                    {
                        return import("@/app/authentication/views/ResetPassword.vue");
                    },
                    props: (route) =>
                    {
                        return Object.assign({}, route.query, route.params);
                    }
                }
                // {
                //     path: "/payment",
                //     name: RoutesEnum.PaymentOnline,
                //     component: () =>
                //     {
                //         return import("@/app/paymentOnline/PaymentOnline.vue");
                //     },
                //     props: (route) =>
                //     {
                //         return Object.assign({}, route.query, route.params);
                //     }
                // }
            ]
        },
        {
            path: "/agreement",
            name: RoutesEnum.Agreement,
            component: () =>
            {
                return import("@/app/reservation/views/Agreement.vue");
            }
        },
        {
            path: "/autologin",
            name: RoutesEnum.AutoLogin,
            component: () =>
            {
                return import("@/app/authentication/views/AutoLogin.vue");
            },
            props: (route) =>
            {
                return Object.assign({}, route.query, route.params);
            }
        },
        {
            path: "/404",
            name: RoutesEnum.NotFound,
            component: () =>
            {
                return import("@/app/common/views/NotFound.vue");
            }
        },
        {
            path: "*",
            redirect: { name: RoutesEnum.NotFound}
        }
    ],
    scrollBehavior (to, from, savedPosition)
    {
        return { x: 0, y: 0 };
    }
});

const routerMethods = ["push", "replace"];

routerMethods.forEach((method: string) =>
{
    const originalCall = (Router.prototype as any)[method];
    (Router.prototype as any)[method] = function(location: any, onResolve: any, onReject: any): Promise<any>
    {
        if (onResolve || onReject)
        {
            return originalCall.call(this, location, onResolve, onReject);
        }
        return (originalCall.call(this, location) as any).catch((err: any) =>
        {
            return err;
        });
    };
});

export default theRouter;