import jwtDecode from "jwt-decode";
import moment from "moment";

class ReservationTokenModel
{
    reservationCode: string = "";
    raw: string = "";
    exp: number = 0;
    reservationDuration: number = 0;

    constructor(p_Token: string | null = null)
    {
        if (ReservationTokenModel.IsSupported())
        {
            if (p_Token)
            {
                let tokenDecoded: any = jwtDecode(p_Token);
                this.reservationCode = tokenDecoded.reservationCode || "";
                this.raw = p_Token || "";
                this.reservationDuration = parseInt(tokenDecoded.reservationDuration || 0);
                this.exp = moment(Date.now()).add(this.reservationDuration, "minute").unix();
                localStorage.setItem((process.env?.VUE_APP_LICENSE ?? "") + "_reserv_token", p_Token);
                localStorage.setItem((process.env?.VUE_APP_LICENSE ?? "") + "_reserv_start_time", this.exp.toString());
            }
            else
            {
                let tokenEncoded: string | null = localStorage.getItem((process.env?.VUE_APP_LICENSE ?? "") + "_reserv_token");
                let startTime: string | null = localStorage.getItem((process.env?.VUE_APP_LICENSE ?? "") + "_reserv_start_time");
                if (tokenEncoded)
                {
                    let tokenDecoded: any = jwtDecode(tokenEncoded);
                    this.reservationCode = tokenDecoded.reservationCode || "";
                    this.raw = tokenEncoded || "";
                }
                if (startTime)
                {
                    this.exp = parseInt(startTime);
                }
            }
        }
    }

    valid()
    {
        return this.reservationCode.length > 0;
    }

    isNotExpired()
    {
        return (Date.now() < this.exp * 1000);
    }

    isReservationInProgress(): boolean
    {
        if (this.valid())
        {
            if (this.isNotExpired())
            {
                return true;
            }
            else
            {
                this.delete();
                return false;
            }
        }
        else
        {
            return false;
        }
    }

    static delete()
    {
        try
        {
            localStorage.removeItem((process.env?.VUE_APP_LICENSE ?? "") + "_reserv_token");
            localStorage.removeItem((process.env?.VUE_APP_LICENSE ?? "") + "_reserv_start_time");
            localStorage.removeItem((process.env?.VUE_APP_LICENSE ?? "") + "_is_payment_deposit_selected");
        }
        catch
        {

        }
    }

    delete()
    {
        try
        {
            localStorage.removeItem((process.env?.VUE_APP_LICENSE ?? "") + "_reserv_token");
            localStorage.removeItem((process.env?.VUE_APP_LICENSE ?? "") + "_reserv_start_time");
            localStorage.removeItem((process.env?.VUE_APP_LICENSE ?? "") + "_is_payment_deposit_selected");
        }
        catch
        {

        }
    }

    static IsSupported(): boolean
    {
        let test = "test";
        try
        {
            localStorage.setItem(test, test);
            localStorage.removeItem(test);
            return true;
        }
        catch(e)
        {
            return false;
        }
    }

    static isPaymentDepositSelected(): boolean
    {
        return (localStorage.getItem((process.env?.VUE_APP_LICENSE ?? "") + "_is_payment_deposit_selected") ?? "") === "1";
    }

    static setIsPaymentDepositSelected(value: boolean)
    {
        if (value)
        {
            localStorage.setItem((process.env?.VUE_APP_LICENSE ?? "") + "_is_payment_deposit_selected", "1");
        }
        else
        {
            localStorage.setItem((process.env?.VUE_APP_LICENSE ?? "") + "_is_payment_deposit_selected", "0");
        }
    }
}

export default ReservationTokenModel;