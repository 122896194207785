import { AxiosInstance } from "axios";
import ApiController from "../_common/apiController";
import { ProductGroupListModel } from "./productGroupData";

class ProductGroupController extends ApiController
{
    constructor(p_Axios: AxiosInstance)
    {
        super(p_Axios, "productgroup", "1.0");
    }

    public async listGroupAsync(): Promise<ProductGroupListModel[]>
    {
        try
        {
            const request = await this.$axios.get<ProductGroupListModel[]>(this.$controllerUrl);
            return request.data;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }
}

export default ProductGroupController;