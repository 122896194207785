import { Payload } from "vuex";
import ActionsEnum from "@/store/storeEnum/actionsEnum";
import MutationsEnum from "@/store/storeEnum/mutationsEnum";
import { LoginTypeEnum } from "@/apiManager/login/loginData";
import { UserCustomerProjectModel } from "@/apiManager/user/userData";

namespace AuthenticationModalPayload
{
    export namespace Actions
    {
        export class Login implements Payload
        {
            public readonly type: string = ActionsEnum.Login;
            public email: string;
            public password: string;

            constructor(p_Email: string, p_Password: string)
            {
                this.email = p_Email;
                this.password = p_Password;
            }
        }
    }

    export namespace Mutations
    {
        export class DisplayAuthenticationModal implements Payload
        {
            readonly type: string = MutationsEnum.DisplayAuthenticationModal;
            sectionDisplayed: number;
            callback: Function | null;

            constructor(p_SectionDisplayed: number, p_Callback: Function | null = null)
            {
                this.sectionDisplayed = p_SectionDisplayed;
                this.callback = p_Callback;
            }
        }

        export class HideAuthenticationModal implements Payload
        {
            readonly type: string = MutationsEnum.HideAuthenticationModal;
        }

        export class SetIsAuth implements Payload
        {
            readonly type: string = MutationsEnum.SetIsAuth;
            value: boolean;
            authType: LoginTypeEnum;

            constructor(p_Value: boolean, p_Type: LoginTypeEnum)
            {
                this.value = p_Value;
                this.authType = p_Type;
            }
        }

        export class SetWelcomeComponent implements Payload
        {
            readonly type: string = MutationsEnum.SetWelcomeComponent;
            value: boolean;
            name: string;

            constructor(p_Value: boolean, p_Name: string = "")
            {
                this.value = p_Value;
                this.name = p_Name;
            }
        }

        export class SetRedirectAfterRegistration implements Payload
        {
            readonly type: string = MutationsEnum.SetRedirectAfterRegistration;
            value: string;

            constructor(p_Value: string)
            {
                this.value = p_Value;
            }
        }

        export class SetVisibleCustomerProjectsModal implements Payload
        {
            readonly type: string = MutationsEnum.SetVisibleCustomerProjectsModal;
            isVisible: boolean;
            listCustomerProjects: UserCustomerProjectModel[];

            constructor(p_IsVisible: boolean, p_ListCustomerProjects: UserCustomerProjectModel[] = [])
            {
                this.isVisible = p_IsVisible;
                this.listCustomerProjects = p_ListCustomerProjects;
            }
        }
    }
}

export default AuthenticationModalPayload;