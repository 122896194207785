import { Payload } from "vuex";
import ActionsEnum from "@/store/storeEnum/actionsEnum";
import MutationsEnum from "@/store/storeEnum/mutationsEnum";
import ProductDetailSelection from "@/models/productDetail/productDetailSelection";
import { ProductGroupListModel } from "@/apiManager/productGroup/productGroupData";
import { ProductDateModel, ProductDetailModel } from "@/apiManager/product/productData";

namespace ProductPayload
{
    export namespace Actions
    {
        export class FetchProducts implements Payload
        {
            public readonly type: string = ActionsEnum.FetchProducts;
            // public behavior: BehaviorEnum;

            constructor()
            {
                // this.behavior = p_Behavior;
            }
        }

        export class FetchProductDetail implements Payload
        {
            public readonly type: string = ActionsEnum.FetchProductDetail;
            public productCode: string;
            public dateCode: number;
            public dateFrom: string;
            public dateTo: string;

            constructor(p_ProductCode: string, p_DateCode: number, p_DateFrom: string, p_DateTo: string)
            {
                this.productCode = p_ProductCode;
                this.dateCode = p_DateCode;
                this.dateFrom = p_DateFrom;
                this.dateTo = p_DateTo;
            }
        }

        export class FetchProductDates implements Payload
        {
            public readonly type: string = ActionsEnum.FetchProductDates;
            public productCode: string;

            constructor(p_ProductCode: string)
            {
                this.productCode = p_ProductCode;
            }
        }
    }

    export namespace Mutations
    {
        export class SetProductGroups implements Payload
        {
            readonly type: string = MutationsEnum.SetProductGroups;
            productGroupList: ProductGroupListModel[];

            constructor(p_ProductGroupList: ProductGroupListModel[])
            {
                this.productGroupList = p_ProductGroupList;
            }
        }

        export class SetProductGroupsIsLoading implements Payload
        {
            readonly type: string = MutationsEnum.SetProductGroupsIsLoading;
            isLoading: boolean;

            constructor(p_IsLoading: boolean)
            {
                this.isLoading = p_IsLoading;
            }
        }

        export class SetProductDetail implements Payload
        {
            readonly type: string = MutationsEnum.SetProductDetail;
            productDetail: ProductDetailModel;

            constructor(p_ProductDetail: ProductDetailModel)
            {
                this.productDetail = p_ProductDetail;
            }
        }

        export class SetProductDetailIsLoading implements Payload
        {
            readonly type: string = MutationsEnum.SetProductDetailIsLoading;
            isLoading: boolean;

            constructor(p_IsLoading: boolean)
            {
                this.isLoading = p_IsLoading;
            }
        }

        export class SetProductDates implements Payload
        {
            readonly type: string = MutationsEnum.SetProductDates;
            productDateList: ProductDateModel[];

            constructor(p_ProductDateList: ProductDateModel[])
            {
                this.productDateList = p_ProductDateList;
            }
        }

        export class SetProductDatesIsLoading implements Payload
        {
            readonly type: string = MutationsEnum.SetProductDatesIsLoading;
            isLoading: boolean;

            constructor(p_IsLoading: boolean)
            {
                this.isLoading = p_IsLoading;
            }
        }

        export class SetProductDetailSelection implements Payload
        {
            readonly type: string = MutationsEnum.SetProductDetailSelection;
            values: ProductDetailSelection;

            constructor(p_values: ProductDetailSelection)
            {
                this.values = p_values;
            }
        }
    }
}

export default ProductPayload;