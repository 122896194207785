
    import { Component, Prop, Vue } from "vue-property-decorator";
    import ModalInformative from "@/app/common/components/ModalInformative.vue";
    import BannerFooter from "./BannerFooter.vue";
    import BannerHeader from "./BannerHeader.vue";
    import ConfigPayload from "@/store/modules/config/models";
    import RoutesEnum from "@/router/routesEnum";
    import { Route, RawLocation } from "vue-router";
    import Store from "@/store";
    import AuthModal from "@/app/authentication/views/AuthModal.vue";
    import AccessToken from "@/models/accessToken";
    import AuthenticationModalPayload from "@/store/modules/authenticationModal/models";
    import ModalInformativePayload from "@/store/modules/modalInformative/models";
    import AuthenticationProxy from "../../../../apiManager/authenticationProxy";
    import CustomerProjectsModal from "@/app/authentication/views/CustomerProjectsModal.vue";
    import { LoginTypeEnum } from "@/apiManager/login/loginData";
    import { RezToursLanguageEnum } from "@/apiManager/_common/enums";

    @Component
    export default class AppLayout extends Vue
    {
        get components()
        {
            let components =
                {
                    "banner-header": BannerHeader,
                    "banner-footer": BannerFooter,
                    "modal-informative": ModalInformative,
                    "auth-modal": AuthModal,
                    "customer-projects-modal": CustomerProjectsModal
                };

            return components;
        }

        @Prop({ type: String, default: "" }) private readonly ml!: string;

        get getConfigIsLoading(): boolean
        {
            return this.$tStore.state.configState.configIsLoading;
        }

        async beforeRouteEnter(p_To: Route, p_From: Route, p_Next: (to?: RawLocation | false | Location) => void)
        {
            try
            {
                await Store.dispatch(new ConfigPayload.Actions.FetchSettings());
                if (Store.state.authenticationModal.isAuth)
                {
                    if (AccessToken.IsSupported())
                    {
                        if (!AccessToken.isNotExpired())
                        {
                            Store.commit(new AuthenticationModalPayload.Mutations.SetIsAuth(false, LoginTypeEnum.None));
                            let payload = new ModalInformativePayload.Mutations.DisplayModalInformative(AuthenticationProxy.i18n(RezToursLanguageEnum.sessionTimeOutTitle), AuthenticationProxy.i18n(RezToursLanguageEnum.sessionTimeOutMsg));
                            Store.commit(payload);
                            Store.commit(new AuthenticationModalPayload.Mutations.DisplayAuthenticationModal(0));
                            AccessToken.delete();
                        }
                        else
                        {
                            Store.commit(new AuthenticationModalPayload.Mutations.SetIsAuth(true, AccessToken.GetType()));
                            let invoiceCode: string | null = sessionStorage.getItem((process.env?.VUE_APP_LICENSE ?? "") + "_invoiceCode");
                            if (invoiceCode)
                            {
                                sessionStorage.removeItem((process.env?.VUE_APP_LICENSE ?? "") + "_invoiceCode");

                                return p_Next({ name: RoutesEnum.OutstandingInvoiceDetail, params: { file: invoiceCode }, replace: true });
                            }

                        }
                    }
                }

                let paymentOnlineToken: string | null = sessionStorage.getItem((process.env?.VUE_APP_LICENSE ?? "") + "_paymentOnlineToken");
                if (paymentOnlineToken)
                {
                    sessionStorage.removeItem((process.env?.VUE_APP_LICENSE ?? "") + "_paymentOnlineToken");

                    return p_Next({ name: RoutesEnum.PaymentOnline, query: { token: paymentOnlineToken }, replace: true });
                }

            }
            catch(ex)
            {
                return p_Next({ name: RoutesEnum.NotFound });
            }

            p_Next();
        }

        created()
        {
            if (this.ml && this.ml.length > 0)
            {
                this.$store.commit(new ConfigPayload.Mutations.SetSettingUseMainLanguage(String(this.ml).toLowerCase() === "true") );
                let query = Object.assign({}, this.$route.query);
                delete query.ml;
                this.$router.replace({ query });
            }
        }
    }
