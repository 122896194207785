
    import { Component, PropSync, Vue } from "vue-property-decorator";
    import AccessToken from "@/models/accessToken";
    import AuthenticationModalPayload from "@/store/modules/authenticationModal/models";
    import ModalInformativePayload from "@/store/modules/modalInformative/models";
    import ProductPayload from "@/store/modules/product/models";
    import { LoginSignInBody, LoginSignInModel, LoginTypeEnum } from "@/apiManager/login/loginData";
    import { UserCustomerModel, UserCustomerProjectModel, UserPaxModel } from "@/apiManager/user/userData";
    import { RezToursLanguageEnum } from "@/apiManager/_common/enums";
    import CustomerProject from "@/models/customerProject";

    @Component
    export default class LoginBox extends Vue
    {
        @PropSync("sectionDisplayed", { type: Number }) private syncedSectionDisplayed!: number;

        private signInEmail: string = "";
        private signInPassword: string = "";
        private signInProgress: boolean = false;
        private hasErrorServer: boolean = false;
        private errorServerMessage: string = "";

        onClickForgetPassword()
        {
            this.syncedSectionDisplayed = 1;
        }

        async onClickLogIn()
        {
            if (!this.signInProgress)
            {
                this.hasErrorServer = false;

                if (AccessToken.IsSupported())
                {
                    this.signInProgress = true;
                    try
                    {
                        let body: LoginSignInBody = new LoginSignInBody(this.signInEmail, this.signInPassword);
                        let tokenModel: LoginSignInModel = await Vue.$apiManager.login.signInAsync(body);

                        AccessToken.Set(tokenModel.token);
                        let loginType: LoginTypeEnum = AccessToken.GetType();

                        this.$tStore.commit(new AuthenticationModalPayload.Mutations.SetIsAuth(true, loginType));
                        if (loginType === LoginTypeEnum.Pax)
                        {
                            let pax: UserPaxModel = await Vue.$apiManager.user.getPaxAsync();
                            this.$tStore.commit(new AuthenticationModalPayload.Mutations.SetWelcomeComponent(true, `${ pax.firstName } ${ pax.lastName }`));
                        }
                        else if (loginType === LoginTypeEnum.CorporateAccount)
                        {
                            let listCustomerProjects: UserCustomerProjectModel[] = await this.$apiManager.user.listCustomerProjectAsync();
                            if (listCustomerProjects.length > 0)
                            {
                                this.$tStore.commit(new AuthenticationModalPayload.Mutations.SetVisibleCustomerProjectsModal(true, listCustomerProjects));
                            }
                            else
                            {
                                let customer: UserCustomerModel = await Vue.$apiManager.user.getCustomerAsync();
                                CustomerProject.Set("");
                                this.$tStore.commit(new AuthenticationModalPayload.Mutations.HideAuthenticationModal());
                                this.$tStore.commit(new AuthenticationModalPayload.Mutations.SetWelcomeComponent(true, `${ customer.name }`));
                            }
                        }

                        if (this.$tStore.state.authenticationModal.redirectAfterRegistration.length > 0)
                        {
                            this.$router.push(this.$tStore.state.authenticationModal.redirectAfterRegistration);
                            this.$tStore.commit(new AuthenticationModalPayload.Mutations.SetRedirectAfterRegistration(""));
                        }

                        if (loginType !== LoginTypeEnum.CorporateAccount)
                        {
                            this.$tStore.commit(new AuthenticationModalPayload.Mutations.HideAuthenticationModal());
                        }

                        this.$tStore.dispatch(new ProductPayload.Actions.FetchProducts());

                        setTimeout(() =>
                        {
                            this.$tStore.commit(new AuthenticationModalPayload.Mutations.SetWelcomeComponent(false));
                        }, 3000);
                    }
                    catch (error: any)
                    {
                        let statusCode: number = error?.response?.status ?? 0;
                        if (statusCode >= 500)
                        {
                            this.errorServerMessage = this.i18n(RezToursLanguageEnum.errorCommPCVWeb);
                        }
                        else if (statusCode >= 400)
                        {
                            this.errorServerMessage = this.i18n(RezToursLanguageEnum.errorSignInInvalid);
                        }
                        else
                        {
                            this.errorServerMessage = this.i18n(RezToursLanguageEnum.serverErrorMsg);
                        }

                        this.hasErrorServer = true;
                    }
                    finally
                    {
                        this.signInProgress = false;
                    }
                }
                else
                {
                    let payloadModal = new ModalInformativePayload.Mutations.DisplayModalInformative(
                        this.i18n(this.getRezToursLanguageEnum.unsupportedCookiesTitle),
                        this.i18n(this.getRezToursLanguageEnum.unsupportedCookiesMessage)
                    );

                    this.$tStore.commit(payloadModal);
                }

            }
        }
    }
