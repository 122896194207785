
    import { Component, Vue } from "vue-property-decorator";
    import { SettingModel } from "@/apiManager/setting/settingData";

    @Component
    export default class Slider extends Vue
    {
        slide = 0;
        sliding: boolean = false;

        get getSetting(): SettingModel
        {
            return this.$tStore.state.configState.setting;
        }

        get getSliders(): SliderData[]
        {
            let list: SliderData[] = [];
            let sliderModel: SliderData;

            if (this.getUseMainLanguage)
            {
                this.$tStore.state.configState.setting.listSlider.forEach((slider) =>
                {
                    sliderModel = new SliderData();
                    sliderModel.pathImage = slider.pathImage;
                    sliderModel.title = slider.languages.lang1.title;
                    sliderModel.description = slider.languages.lang1.description;
                    sliderModel.linkEnabled = slider.languages.lang1.link.enabled;
                    sliderModel.linkUrl = slider.languages.lang1.link.url;
                    sliderModel.linkText = slider.languages.lang1.link.text;
                    sliderModel.linkRedirect = slider.languages.lang1.link.redirect;
                    list.push(sliderModel);
                });
            }
            else
            {
                this.$tStore.state.configState.setting.listSlider.forEach((slider) =>
                {
                    sliderModel = new SliderData();
                    sliderModel.pathImage = slider.pathImage;
                    sliderModel.title = slider.languages.lang2.title;
                    sliderModel.description = slider.languages.lang2.description;
                    sliderModel.linkEnabled = slider.languages.lang2.link.enabled;
                    sliderModel.linkUrl = slider.languages.lang2.link.url;
                    sliderModel.linkText = slider.languages.lang2.link.text;
                    sliderModel.linkRedirect = slider.languages.lang2.link.redirect;
                    list.push(sliderModel);
                });
            }

            return list;
        }

        onSlideStart(slide: any)
        {
            this.sliding = true;
        }

        onSlideEnd(slide: any)
        {
            this.sliding = false;
        }

        SetUrlImage(p_ImageUrl: string)
        {
            let url = this.getSetting.license.urlWebSiteServer + p_ImageUrl;
            return "background-image: url(" + url + ");";
        }

        onSliderClick(p_Slider: SliderData)
        {
            let linkUrl: string = "";
            if ((p_Slider.linkUrl.startsWith("https://")) || (p_Slider.linkUrl.startsWith("http://")))
            {
                linkUrl = p_Slider.linkUrl;
            }
            else
            {
                linkUrl = `https://${ p_Slider.linkUrl }`;
            }

            if (p_Slider.linkRedirect)
            {
                window.location.href = linkUrl;
            }
            else
            {
                window.open(linkUrl, "_blank");
            }
        }
    }

    class SliderData
    {
        pathImage: string = "";
        title: string = "";
        description: string = "";
        linkEnabled: boolean = false;
        linkUrl: string = "";
        linkText: string = "";
        linkRedirect: boolean = false;
    }
