import { AxiosInstance } from "axios";
import ApiController from "../_common/apiController";
import { UserCustomerModel, UserCustomerProjectModel, UserPaxModel, UserUpdateCustomerBody, UserUpdateEmailBody, UserUpdatePasswordBody, UserUpdatePaxBody } from "./userData";
import { LoginSignInModel } from "../login/loginData";

class UserController extends ApiController
{
    constructor(p_Axios: AxiosInstance)
    {
        super(p_Axios, "user", "1.0");
    }

    public async getPaxAsync(): Promise<UserPaxModel>
    {
        try
        {
            const request = await this.$axios.get<UserPaxModel>(`${ this.$controllerUrl }/pax`);
            return request.data;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }

    public async getCustomerAsync(): Promise<UserCustomerModel>
    {
        try
        {
            const request = await this.$axios.get<UserCustomerModel>(`${ this.$controllerUrl }/customer`);
            return request.data;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }

    public async listCustomerProjectAsync(): Promise<UserCustomerProjectModel[]>
    {
        try
        {
            const request = await this.$axios.get<UserCustomerProjectModel[]>(`${ this.$controllerUrl }/projects`);
            return request.data;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }

    public async updateCustomerAsync(p_Body: UserUpdateCustomerBody): Promise<boolean>
    {
        try
        {
            await this.$axios.put(`${ this.$controllerUrl }/customer`, p_Body);
            return true;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }

    public async updatePaxAsync(p_Body: UserUpdatePaxBody): Promise<boolean>
    {
        try
        {
            await this.$axios.put(`${ this.$controllerUrl }/pax`, p_Body);
            return true;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }

    public async updateEmailAsync(p_Body: UserUpdateEmailBody): Promise<LoginSignInModel>
    {
        try
        {
            const request = await this.$axios.put<LoginSignInModel>(`${ this.$controllerUrl }/email`, p_Body);
            return request.data;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }

    public async updatePasswordAsync(p_Body: UserUpdatePasswordBody): Promise<boolean>
    {
        try
        {
            await this.$axios.put(`${ this.$controllerUrl }/password`, p_Body);
            return true;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }
}

export default UserController;