
    import { Component, PropSync, Vue } from "vue-property-decorator";

    @Component
    export default class ConfirmationPassword extends Vue
    {
        @PropSync("sectionDisplayed", { type: Number }) private syncedSectionDisplayed!: number;

        onClickReturn()
        {
            this.syncedSectionDisplayed = 0;
        }
    }
