
    import { Component, Vue } from "vue-property-decorator";
    import ProductGroupLabel from "./ProductGroupLabel.vue";
    import ProductItem from "./ProductItem.vue";
    import PopupProductDates from "@/app/product/components/PopupProductDates/PopupProductDates.vue";
    import TransitionExpand from "@/app/common/components/TransitionExpand.vue";
    import { ProductGroupListModel, ProductGroupListProductModel } from "@/apiManager/productGroup/productGroupData";

    @Component
    export default class ProductGroup extends Vue
    {
        get components()
        {
            let components =
                {
                    "product-group-label": ProductGroupLabel,
                    "product-item": ProductItem,
                    "popup-product-dates": PopupProductDates,
                    "transition-expand": TransitionExpand
                };
            return components;
        }

        productGroupIndexSelected: number = -1;
        productGroupSelected: ProductGroupListModel = new ProductGroupListModel();
        productGroupByLine: number = 0;
        popupDatesOpen: boolean = false;
        popupDatesProduct: ProductGroupListProductModel = new ProductGroupListProductModel();

        updateProductGroupSelected(productGroup: ProductGroupListModel, index: number)
        {
            if (this.productGroupIndexSelected === index)
            {
                this.onCloseProductGroup();
            }
            else
            {
                this.productGroupIndexSelected = index;
                this.productGroupSelected = productGroup;
            }
        }

        createDivDetail(index: number)
        {
            if (this.productGroupByLine > 0)
            {
                return index % this.productGroupByLine === 0 || (index === this.getProductGroups.length && index % this.productGroupByLine !== 0);
            }
            else
            {
                return false;
            }
        }

        showDivDetail(index: number)
        {
            let indexDivDetail: number = index;

            if (index % this.productGroupByLine !== 0)
            {
                indexDivDetail = indexDivDetail - (index % this.productGroupByLine) + this.productGroupByLine;
            }

            if (this.productGroupByLine > 0 && this.productGroupIndexSelected > -1)
            {
                return (
                    Math.ceil(this.productGroupIndexSelected / this.productGroupByLine) === indexDivDetail / this.productGroupByLine
                );
            }
            else
            {
                return false;
            }
        }

        get getProductGroups(): ProductGroupListModel[]
        {
            this.myResize();
            return this.$tStore.state.productState.productGroups;
        }

        onCloseProductGroup()
        {
            this.productGroupIndexSelected = -1;
        }

        destroyed()
        {
            window.removeEventListener("resize", this.myResize, true);
        }

        created()
        {
            window.addEventListener("resize", this.myResize, true);
        }

        mounted()
        {
            this.myResize();
        }

        myResize()
        {
            //total number of element
            var n_t = document.querySelectorAll(".product-group-label-models").length;
            //width of an element
            var querySelectorCI: any = document.querySelector(".product-group-label-models");
            var querySelectorGrid: any = document.querySelector(".product-group-grid");

            if (querySelectorCI && querySelectorGrid)
            {
                var w: number = parseInt(querySelectorCI.offsetWidth);
                //full width of element with margin
                var m: any =
                    querySelectorCI.currentStyle ||
                    window.getComputedStyle(querySelectorCI);
                w = w + parseInt(m.marginLeft) + parseInt(m.marginRight);

                //width of container
                var w_c: number = parseInt(querySelectorGrid.offsetWidth);

                //padding of container
                var c: any =
                    querySelectorGrid.currentStyle ||
                    window.getComputedStyle(querySelectorGrid);

                var p_c: number = parseInt(c.paddingLeft) + parseInt(c.paddingRight);

                //nb element per row
                var nb: number = Math.min((w_c - p_c) / w, n_t);

                this.productGroupByLine = Math.round(nb);
            }
        }

        onOpenPopupDates(p_Product: ProductGroupListProductModel)
        {
            this.popupDatesProduct = p_Product;
            this.popupDatesOpen = true;
        }
    }
