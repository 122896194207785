import { Module } from "vuex";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { RootState } from "@/store/types";
import ConfigState from "./types";
import LanguageSession from "@/models/languageSession";
import { SettingModel } from "@/apiManager/setting/settingData";

const namespaced: boolean = false;

export const state: ConfigState =
{
    setting: new SettingModel(),
    configIsLoading: false,
    useMainLanguage: LanguageSession.getUseMainLanguage()
};

export const configState: Module<ConfigState, RootState> =
{
    namespaced,
    state,
    actions,
    getters,
    mutations
};