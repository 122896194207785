enum ActionsEnum
{
    CreateReservation = "CreateReservation",
    DeleteReservation = "DeleteReservation",

    FetchSettings = "FetchSettings",

    FetchProducts = "FetchProducts",
    FetchProductDates = "FetchProductDates",
    FetchProductDetail = "FetchProductDetail",
    FetchReservation = "FetchReservation",
    FetchTravelBag = "FetchTravelBag",
    SetPassengerReservation = "SetPassengerReservation",

    CreateInvoice = "CreateInvoice",
    CancelPayment = "CancelPayment",

    Login = "Login",
}

export default ActionsEnum;