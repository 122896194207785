import { MutationTree } from "vuex";
import MutationsEnum from "@/store/storeEnum/mutationsEnum";
import ConfigState from "./types";
import ConfigPayload from "./models";
import LanguageSession from "@/models/languageSession";

export const mutations: MutationTree<ConfigState> =
{
    [MutationsEnum.SetSetting](p_State: ConfigState, p_Payload: ConfigPayload.Mutations.SetSetting)
    {
        p_State.setting = p_Payload.setting;
        document.title = p_Payload.setting.license.title;
    },

    [MutationsEnum.SetSettingsIsLoading](p_State: ConfigState, p_Payload: ConfigPayload.Mutations.SetSettingsIsLoading)
    {
        p_State.configIsLoading = p_Payload.isLoading;
    },

    [MutationsEnum.SetSettingUseMainLanguage](p_State: ConfigState, p_Payload: ConfigPayload.Mutations.SetSettingUseMainLanguage)
    {
        p_State.useMainLanguage = p_Payload.useMainLanguage;
        LanguageSession.set(p_State.useMainLanguage);
    }
};