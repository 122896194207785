import Vue from "vue";
import { MutationTree } from "vuex";
import ProductState from "./types";
import MutationsEnum from "@/store/storeEnum/mutationsEnum";
import ProductPayload from "./models";

export const mutations: MutationTree<ProductState> =
{
    [MutationsEnum.SetProductGroups](p_State: ProductState, p_Payload: ProductPayload.Mutations.SetProductGroups)
    {
        p_State.productGroups = p_Payload.productGroupList;
    },

    [MutationsEnum.SetProductGroupsIsLoading](p_State: ProductState, p_Payload: ProductPayload.Mutations.SetProductGroupsIsLoading)
    {
        p_State.productGroupsIsLoading = p_Payload.isLoading;
    },

    [MutationsEnum.SetProductDetail](p_State: ProductState, p_Payload: ProductPayload.Mutations.SetProductDetail)
    {
        p_State.productDetail = p_Payload.productDetail;
    },

    [MutationsEnum.SetProductDetailIsLoading](p_State: ProductState, p_Payload: ProductPayload.Mutations.SetProductDetailIsLoading)
    {
        p_State.productDetailIsLoading = p_Payload.isLoading;
    },

    [MutationsEnum.SetProductDates](p_State: ProductState, p_Payload: ProductPayload.Mutations.SetProductDates)
    {
        p_State.productDates = p_Payload.productDateList;
    },

    [MutationsEnum.SetProductDatesIsLoading](p_State: ProductState, p_Payload: ProductPayload.Mutations.SetProductDatesIsLoading)
    {
        p_State.productDatesIsLoading = p_Payload.isLoading;
    },

    [MutationsEnum.SetProductDetailSelection](p_State: ProductState, p_Payload: ProductPayload.Mutations.SetProductDetailSelection)
    {
        p_State.productDetailSelection = p_Payload.values;
    }
};