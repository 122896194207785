import { Payload } from "vuex";
import ActionsEnum from "@/store/storeEnum/actionsEnum";
import MutationsEnum from "@/store/storeEnum/mutationsEnum";
import { ReservationBodyOption, ReservationModel } from "@/apiManager/reservation/reservationData";
import { PaymentStatusEnum } from "@/apiManager/_common/enums";
import { InvoiceCreateModel } from "@/apiManager/invoice/invoiceData";

namespace ReservationPayload
{
    export namespace Actions
    {
        export class FetchReservation implements Payload
        {
            public readonly type: string = ActionsEnum.FetchReservation;
            public reservCode: string;

            constructor(p_ReservCode: string)
            {
                this.reservCode = p_ReservCode;
            }
        }

        export class CreateReservation implements Payload
        {
            public readonly type: string = ActionsEnum.CreateReservation;
            public product: string;
            public productPriceDateCode: number;
            public productPriceClassCode: number;
            public productPriceCategoryCode: number;
            public pricingClassCode: number;
            public pricingCategoryCode: number;
            public dateDepart: string;
            public dateReturn: string;
            public nbAdult: number;
            public nbChild: number;
            public nbRoom: number;
            public listOption: ReservationBodyOption[];
            public busPickup: number;

            constructor(p_Product: string, p_ProductPriceDateCode: number, p_ProductPriceClassCode: number, p_ProductPriceCategoryCode: number, p_PricingClassCode: number, p_PricingCategoryCode: number, p_DateDepart: string, p_DateReturn: string, p_NbAdult: number, p_NbChild: number, p_NbRoom: number, p_ListOption: ReservationBodyOption[], p_BusPickup: number)
            {
                this.product = p_Product;
                this.productPriceDateCode = p_ProductPriceDateCode;
                this.productPriceClassCode = p_ProductPriceClassCode;
                this.productPriceCategoryCode = p_ProductPriceCategoryCode;
                this.pricingClassCode = p_PricingClassCode;
                this.pricingCategoryCode = p_PricingCategoryCode;
                this.dateDepart = p_DateDepart;
                this.dateReturn = p_DateReturn;
                this.nbAdult = p_NbAdult;
                this.nbChild = p_NbChild;
                this.nbRoom = p_NbRoom;
                this.listOption = p_ListOption;
                this.busPickup = p_BusPickup;
            }
        }

        export class DeleteReservation implements Payload
        {
            public readonly type: string = ActionsEnum.DeleteReservation;
            public reservCode: string;

            constructor(p_ReservCode: string)
            {
                this.reservCode = p_ReservCode;
            }
        }

        export class SetPassengerReservation implements Payload
        {
            public readonly type: string = ActionsEnum.SetPassengerReservation;

            constructor()
            {
            }
        }

        export class CreateInvoice implements Payload
        {
            public readonly type: string = ActionsEnum.CreateInvoice;
            public isPaymentDisabled: boolean;
            public language: string;

            constructor(p_Language: string, p_IsPaymentDisabled: boolean)
            {
                this.isPaymentDisabled = p_IsPaymentDisabled;
                this.language = p_Language;
            }
        }

        export class CancelPayment implements Payload
        {
            public readonly type: string = ActionsEnum.CancelPayment;

            public reservCode: string;
            public paymentIndex: number;

            constructor(p_ReservCode: string, p_PaymentIndex: number)
            {
                this.reservCode = p_ReservCode;
                this.paymentIndex = p_PaymentIndex;
            }
        }
    }

    export namespace Mutations
    {
        export class SetReservationDetail implements Payload
        {
            readonly type: string = MutationsEnum.SetReservationDetail;
            public detail: ReservationModel;

            constructor(p_Detail: ReservationModel)
            {
                this.detail = p_Detail;
            }
        }

        export class SetReservationPaxLastName implements Payload
        {
            readonly type: string = MutationsEnum.SetReservationPaxLastName;
            public lastName: string;
            public indexRoom: number;
            public indexPax: number;

            constructor(p_lastName: string, p_indexRoom: number, p_indexPax: number)
            {
                this.lastName = p_lastName;
                this.indexRoom = p_indexRoom;
                this.indexPax = p_indexPax;
            }
        }

        export class SetReservationPaxFirstName implements Payload
        {
            readonly type: string = MutationsEnum.SetReservationPaxFirstName;
            public firstName: string;
            public indexRoom: number;
            public indexPax: number;

            constructor(p_firstName: string, p_indexRoom: number, p_indexPax: number)
            {
                this.firstName = p_firstName;
                this.indexRoom = p_indexRoom;
                this.indexPax = p_indexPax;
            }
        }

        export class SetReservationPaxTelephone implements Payload
        {
            readonly type: string = MutationsEnum.SetReservationPaxTelephone;
            public telephone: string;
            public indexRoom: number;
            public indexPax: number;

            constructor(p_telephone: string, p_indexRoom: number, p_indexPax: number)
            {
                this.telephone = p_telephone;
                this.indexRoom = p_indexRoom;
                this.indexPax = p_indexPax;
            }
        }

        export class SetReservationPaxLanguage implements Payload
        {
            readonly type: string = MutationsEnum.SetReservationPaxLanguage;
            public language: string;
            public indexRoom: number;
            public indexPax: number;

            constructor(p_Language: string, p_indexRoom: number, p_indexPax: number)
            {
                this.language = p_Language;
                this.indexRoom = p_indexRoom;
                this.indexPax = p_indexPax;
            }
        }

        export class SetReservationPaxEmail implements Payload
        {
            readonly type: string = MutationsEnum.SetReservationPaxEmail;
            public email: string;
            public indexRoom: number;
            public indexPax: number;

            constructor(p_email: string, p_indexRoom: number, p_indexPax: number)
            {
                this.email = p_email;
                this.indexRoom = p_indexRoom;
                this.indexPax = p_indexPax;
            }
        }

        export class SetReservationPaxAddress implements Payload
        {
            readonly type: string = MutationsEnum.SetReservationPaxAddress;
            public address: string;
            public indexRoom: number;
            public indexPax: number;

            constructor(p_address: string, p_indexRoom: number, p_indexPax: number)
            {
                this.address = p_address;
                this.indexRoom = p_indexRoom;
                this.indexPax = p_indexPax;
            }
        }

        export class SetReservationPaxCity implements Payload
        {
            readonly type: string = MutationsEnum.SetReservationPaxCity;
            public city: string;
            public indexRoom: number;
            public indexPax: number;

            constructor(p_city: string, p_indexRoom: number, p_indexPax: number)
            {
                this.city = p_city;
                this.indexRoom = p_indexRoom;
                this.indexPax = p_indexPax;
            }
        }

        export class SetReservationPaxProvince implements Payload
        {
            readonly type: string = MutationsEnum.SetReservationPaxProvince;
            public province: string;
            public indexRoom: number;
            public indexPax: number;

            constructor(p_province: string, p_indexRoom: number, p_indexPax: number)
            {
                this.province = p_province;
                this.indexRoom = p_indexRoom;
                this.indexPax = p_indexPax;
            }
        }

        export class SetReservationPaxPostal implements Payload
        {
            readonly type: string = MutationsEnum.SetReservationPaxPostal;
            public postal: string;
            public indexRoom: number;
            public indexPax: number;

            constructor(p_postal: string, p_indexRoom: number, p_indexPax: number)
            {
                this.postal = p_postal;
                this.indexRoom = p_indexRoom;
                this.indexPax = p_indexPax;
            }
        }

        export class SetReservationPaxSameAddress implements Payload
        {
            readonly type: string = MutationsEnum.SetReservationPaxSameAddress;
            public useSameAddress: boolean;
            public indexRoom: number;
            public indexPax: number;

            constructor(p_UseSameAddress: boolean, p_indexRoom: number, p_indexPax: number)
            {
                this.useSameAddress = p_UseSameAddress;
                this.indexRoom = p_indexRoom;
                this.indexPax = p_indexPax;
            }
        }

        export class SetReservationPaxGender implements Payload
        {
            readonly type: string = MutationsEnum.SetReservationPaxGender;
            public gender: string
            public indexRoom: number;
            public indexPax: number;

            constructor(p_Gender: string, p_indexRoom: number, p_indexPax: number)
            {
                this.gender = p_Gender;
                this.indexRoom = p_indexRoom;
                this.indexPax = p_indexPax;
            }
        }

        export class SetReservationPaxBirthdate implements Payload
        {
            readonly type: string = MutationsEnum.SetReservationPaxBirthdate;
            public birthdate: string;
            public indexRoom: number;
            public indexPax: number;

            constructor(p_Birthdate: string, p_indexRoom: number, p_indexPax: number)
            {
                this.birthdate = p_Birthdate;
                this.indexRoom = p_indexRoom;
                this.indexPax = p_indexPax;
            }
        }

        export class SetReservationPaxAllergies implements Payload
        {
            readonly type: string = MutationsEnum.SetReservationPaxAllergies;
            public allergies: string;
            public indexRoom: number;
            public indexPax: number;

            constructor(p_Allergies: string, p_indexRoom: number, p_indexPax: number)
            {
                this.allergies = p_Allergies;
                this.indexRoom = p_indexRoom;
                this.indexPax = p_indexPax;
            }
        }

        export class SetReservationPaxHasNoAllergies implements Payload
        {
            readonly type: string = MutationsEnum.SetReservationPaxHasNoAllergies;
            public hasNoAllergies: boolean;
            public indexRoom: number;
            public indexPax: number;

            constructor(p_HasNoAllergies: boolean, p_indexRoom: number, p_indexPax: number)
            {
                this.hasNoAllergies = p_HasNoAllergies;
                this.indexRoom = p_indexRoom;
                this.indexPax = p_indexPax;
            }
        }

        export class SetReservationPaxMedicalCondition implements Payload
        {
            readonly type: string = MutationsEnum.SetReservationPaxMedicalCondition;
            public medicalCondition: string;
            public indexRoom: number;
            public indexPax: number;

            constructor(p_MedicalCondition: string, p_indexRoom: number, p_indexPax: number)
            {
                this.medicalCondition = p_MedicalCondition;
                this.indexRoom = p_indexRoom;
                this.indexPax = p_indexPax;
            }
        }

        export class SetReservationPaxHasNoMedicalCondition implements Payload
        {
            readonly type: string = MutationsEnum.SetReservationPaxHasNoMedicalCondition;
            public hasNoMedicalCondition: boolean;
            public indexRoom: number;
            public indexPax: number;

            constructor(p_HasNoMedicalCondition: boolean, p_indexRoom: number, p_indexPax: number)
            {
                this.hasNoMedicalCondition = p_HasNoMedicalCondition;
                this.indexRoom = p_indexRoom;
                this.indexPax = p_indexPax;
            }
        }

        export class SetReservationPaxSpecialRequest implements Payload
        {
            readonly type: string = MutationsEnum.SetReservationPaxSpecialRequest;
            public specialRequest: string;
            public indexRoom: number;
            public indexPax: number;

            constructor(p_SpecialRequest: string, p_indexRoom: number, p_indexPax: number)
            {
                this.specialRequest = p_SpecialRequest;
                this.indexRoom = p_indexRoom;
                this.indexPax = p_indexPax;
            }
        }

        export class SetReservationPaxTelephoneWork implements Payload
        {
            readonly type: string = MutationsEnum.SetReservationPaxTelephoneWork;
            public telephoneWork: string;
            public indexRoom: number;
            public indexPax: number;

            constructor(p_TelephoneWork: string, p_indexRoom: number, p_indexPax: number)
            {
                this.telephoneWork = p_TelephoneWork;
                this.indexRoom = p_indexRoom;
                this.indexPax = p_indexPax;
            }
        }

        export class SetReservationPaxTelephoneWorkExt implements Payload
        {
            readonly type: string = MutationsEnum.SetReservationPaxTelephoneWorkExt;
            public telephoneWorkExt: string;
            public indexRoom: number;
            public indexPax: number;

            constructor(p_TelephoneWorkExt: string, p_indexRoom: number, p_indexPax: number)
            {
                this.telephoneWorkExt = p_TelephoneWorkExt;
                this.indexRoom = p_indexRoom;
                this.indexPax = p_indexPax;
            }
        }

        export class SetReservationBackgroundImagePath implements Payload
        {
            readonly type: string = MutationsEnum.SetReservationBackgroundImagePath;
            public backgroundImagePath: string;

            constructor(p_BackgroundImagePath: string)
            {
                this.backgroundImagePath = p_BackgroundImagePath;
            }
        }

        export class UpdateReservationPaymentStatus implements Payload
        {
            readonly type: string = MutationsEnum.UpdateReservationPaymentStatus;
            public paymentIndex: number;
            public newStatus: PaymentStatusEnum;

            constructor(p_PaymentIndex: number, P_NewStatus: PaymentStatusEnum)
            {
                this.paymentIndex = p_PaymentIndex;
                this.newStatus = P_NewStatus;
            }
        }

        export class SetRedirect implements Payload
        {
            readonly type: string = MutationsEnum.SetRedirect;
        }

        export class SetInvoice implements Payload
        {
            readonly type: string = MutationsEnum.SetInvoice;
            public model: InvoiceCreateModel;

            constructor(p_Model: InvoiceCreateModel)
            {
                this.model = p_Model;
            }
        }

        export class SetShowModalCancelReservation implements Payload
        {
            readonly type: string = MutationsEnum.SetShowModalCancelReservation;
            public value: boolean;

            constructor(p_Value: boolean)
            {
                this.value = p_Value;
            }
        }

        export class SetLayoutLoading implements Payload
        {
            readonly type: string = MutationsEnum.SetLayoutLoading;
            public value: boolean;

            constructor(p_Value: boolean)
            {
                this.value = p_Value;
            }
        }

        export class SetIntervalTimer implements Payload
        {
            readonly type: string = MutationsEnum.SetIntervalTimer;
            public value: number;

            constructor(p_Value: number)
            {
                this.value = p_Value;
            }
        }

        export class ClearIntervalTimer implements Payload
        {
            readonly type: string = MutationsEnum.ClearIntervalTimer;
        }

        export class SetModalCancelReservationLogOut implements Payload
        {
            readonly type: string = MutationsEnum.SetModalCancelReservationLogOut;
            public value: boolean;

            constructor(p_Value: boolean)
            {
                this.value = p_Value;
            }
        }

        export class SetModalCancelReservationRedirect implements Payload
        {
            readonly type: string = MutationsEnum.SetModalCancelReservationRedirect;
            public value: string;

            constructor(p_Value: string)
            {
                this.value = p_Value;
            }
        }

    }
}

export default ReservationPayload;