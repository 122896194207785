
    import { Component, Vue } from "vue-property-decorator";
    import ConfigPayload from "@/store/modules/config/models";
    import RoutesEnum from "@/router/routesEnum";
    import SocialNetworkHeader from "./SocialNetworkHeader.vue";
    import ProductPayload from "@/store/modules/product/models";
    import AccessToken from "@/models/accessToken";
    import AuthenticationModalPayload from "@/store/modules/authenticationModal/models";
    import ReservationTokenModel from "../../../../models/reservationTokenModel";
    import ReservationPayload from "@/store/modules/reservation/models";
    import { SettingAgencyLanguageModel, SettingMenuLanguageModel, SettingModel } from "@/apiManager/setting/settingData";
    import { LoginTypeEnum } from "@/apiManager/login/loginData";

    @Component
    export default class BannerHeaderMobile extends Vue
    {
        get components()
        {
            let components = {
                "social-network-header": SocialNetworkHeader
            };

            return components;
        }

        private isScrolling: boolean = false;
        private isOpen: boolean = false;
        private active:boolean= false;
        private monCoeur: string[] = ["fal", "heart"];
        private show: boolean= false;
        private disconnected:boolean = false;

        get getSetting(): SettingModel
        {
            return this.$tStore.state.configState.setting;
        }

        get getLanguages(): SettingAgencyLanguageModel[]
        {
            let ret: SettingAgencyLanguageModel[] = [];
            ret.push(this.getSetting.agency.lang2);
            ret.push(this.getSetting.agency.lang1);
            return ret;
        }

        get getUrlLogo(): string
        {
            return (
                this.getSetting.license.urlWebSiteServer + this.translateValue(this.getSetting.agency.logo)
            );
        }

        get getIsAuth(): boolean
        {
            return this.$tStore.state.authenticationModal.isAuth;
        }

        get getIsAgent(): boolean
        {
            if (this.getIsAuth)
            {
                return (AccessToken.GetType() === LoginTypeEnum.Agent);
            }
            return false;
        }

        get getIsAuthRequired(): boolean
        {
            return this.$tStore.state.configState.setting.license.authIsRequired;
        }

        get getIsShowWelcomeComponent(): boolean
        {
            return this.$tStore.state.authenticationModal.isShowWelcomeComponent;
        }

        get getNameWelcomeComponent(): string
        {
            return this.$tStore.state.authenticationModal.welcomeName;
        }

        get g_ListMenu(): SettingMenuLanguageModel[]
        {
            let list: SettingMenuLanguageModel[] = [];

            if (this.getUseMainLanguage)
            {
                this.$tStore.state.configState.setting.listMenu.forEach((menu) =>
                {
                    list.push(menu.languages.lang1);
                });
            }
            else
            {
                this.$tStore.state.configState.setting.listMenu.forEach((menu) =>
                {
                    list.push(menu.languages.lang2);
                });
            }

            return list;
        }

        onMouseOver()
        {
            this.monCoeur = ["fal", "user-circle"];
        }

        onMouseLeave()
        {
            this.monCoeur = ["fal", "heart"];
        }

        onClickLogo()
        {
            let resev = new ReservationTokenModel();
            if (resev.isReservationInProgress())
            {
                this.$tStore.commit(new ReservationPayload.Mutations.SetModalCancelReservationRedirect(RoutesEnum.Package));
                this.$tStore.commit(new ReservationPayload.Mutations.SetShowModalCancelReservation(true));
            }
            else
            {
                if (this.$route.name !== RoutesEnum.Package)
                {
                    this.$router.push({ name: RoutesEnum.Package });
                }
                else
                {
                    this.$tStore.dispatch(new ProductPayload.Actions.FetchProducts());
                }
            }
        }

        onClickLanguage(p_IndexLanguage: number)
        {
            this.$tStore.commit(new ConfigPayload.Mutations.SetSettingUseMainLanguage(p_IndexLanguage === 1));
        }

        destroyed()
        {
            window.removeEventListener("scroll", this.onScroll, true);
        }

        created()
        {
            window.addEventListener("scroll", this.onScroll, true);
        }

        onScroll()
        {
            this.isScrolling =
                document.body.scrollTop > 80 ||
                document.documentElement.scrollTop > 80;
        }

        onClickProfile()
        {
            let resev = new ReservationTokenModel();
            if (resev.isReservationInProgress())
            {
                this.$tStore.commit(new ReservationPayload.Mutations.SetModalCancelReservationRedirect(RoutesEnum.Profile));
                this.$tStore.commit(new ReservationPayload.Mutations.SetShowModalCancelReservation(true));
            }
            else
            {
                this.$router.push(
                    { name: RoutesEnum.Profile }
                );
            }
            this.show = false;
        }

        onClickList()
        {
            let resev = new ReservationTokenModel();
            if (resev.isReservationInProgress())
            {
                this.$tStore.commit(new ReservationPayload.Mutations.SetModalCancelReservationRedirect(RoutesEnum.OutstandingInvoiceList));
                this.$tStore.commit(new ReservationPayload.Mutations.SetShowModalCancelReservation(true));
            }
            else
            {
                this.$router.push(
                    { name: RoutesEnum.OutstandingInvoiceList }
                );
            }
            this.show = false;
        }

        onClickHome()
        {
            this.$router.push({ name: RoutesEnum.Package });
        }

        hideMessageDisconnect()
        {
            this.disconnected = false;
        }

        onClickLogOff()
        {
            this.disconnected = false;
            let resev = new ReservationTokenModel();
            if (!resev.isReservationInProgress())
            {
                AccessToken.delete();
                this.$tStore.commit(new AuthenticationModalPayload.Mutations.SetIsAuth(false, LoginTypeEnum.None));
                this.$tStore.dispatch(new ProductPayload.Actions.FetchProducts());
                this.onClickHome();
                this.disconnected = true;
                setTimeout(this.hideMessageDisconnect, 3000);
            }
            else
            {
                this.$tStore.commit(new ReservationPayload.Mutations.SetModalCancelReservationLogOut(true));
                this.$tStore.commit(new ReservationPayload.Mutations.SetShowModalCancelReservation(true));
            }
            this.show = false;
        }

        onMouseLeaveDropdown()
        {
            this.show = false;
        }

        onclickOpenConnectionBox()
        {
            if (this.getIsAuth)
            {
                this.show = true;
            }
            else
            {
                this.show = false;
                this.$store.commit(new AuthenticationModalPayload.Mutations.DisplayAuthenticationModal(0));
            }
        }

        onClickInscription()
        {
            this.show = false;
            this.$router.push({name: RoutesEnum.Inscription });
            this.$tStore.commit(new AuthenticationModalPayload.Mutations.HideAuthenticationModal());
        }

        onMenuClick(p_Menu: SettingMenuLanguageModel)
        {
            let url: string = "";
            if ((p_Menu.url.startsWith("https://")) || (p_Menu.url.startsWith("http://")))
            {
                url = p_Menu.url;
            }
            else
            {
                url = `https://${ p_Menu.url }`;
            }

            if (p_Menu.redirect)
            {
                window.location.href = url;
            }
            else
            {
                window.open(url, "_blank");
            }
        }
    }
