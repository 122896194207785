import { Module } from "vuex";
import { mutations } from "./mutations";
import { RootState } from "@/store/types";
import ModalInformativeState from "./types";

export const state: ModalInformativeState =
{
    display: false,
    title: "",
    message: ""
};

const namespaced: boolean = false;

export const modalInformativeState: Module<ModalInformativeState, RootState> = {
    namespaced,
    state,
    mutations
};