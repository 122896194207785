class CustomerProject
{
    static Get(): string
    {
        try
        {
            return localStorage.getItem((process.env?.VUE_APP_LICENSE ?? "") + "_customer_project") ?? "";
        }
        catch
        {
            return "";
        }
    }

    static Set(p_CustomerProject: string)
    {
        try
        {
            localStorage.setItem((process.env?.VUE_APP_LICENSE ?? "") + "_customer_project", p_CustomerProject);
        }
        catch
        {
        }
    }

    static delete()
    {
        try
        {
            localStorage.removeItem((process.env?.VUE_APP_LICENSE ?? "") + "_customer_project");
        }
        catch
        {

        }
    }
}

export default CustomerProject;