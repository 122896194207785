import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import AccessToken from "@/models/accessToken";
import Store from "@/store";
import { LoginTypeEnum } from "./login/loginData";
import AuthenticationModalPayload from "@/store/modules/authenticationModal/models";
import ModalInformativePayload from "@/store/modules/modalInformative/models";
import InvoiceController from "./invoice/invoiceController";
import LoginController from "./login/loginController";
import PaxController from "./pax/paxController";
import PaymentController from "./payment/paymentController";
import ProductController from "./product/productController";
import ProductGroupController from "./productGroup/productGroupController";
import ReservationController from "./reservation/reservationController";
import SettingController from "./setting/settingController";
import UserController from "./user/userController";
import { RezToursLanguageEnum } from "./_common/enums";

class ApiManager
{

    private licenseCode: string;
    private $_axios: AxiosInstance;

    public invoice: InvoiceController;
    public login: LoginController;
    public pax: PaxController;
    public payment: PaymentController;
    public product: ProductController;
    public productGroup: ProductGroupController;
    public reservation: ReservationController;
    public setting: SettingController;
    public user: UserController;

    constructor()
    {
        this.licenseCode = process.env.VUE_APP_LICENSE || "";

        let basUrl = process.env.VUE_APP_API_URL || "";
        let config: AxiosRequestConfig = {
            baseURL: basUrl + "/"
        };

        config.withCredentials = true;
        this.$_axios = axios.create(config);

        this.$_axios.interceptors.request.use(this.interceptorRequest.bind(this));
        this.$_axios.interceptors.response.use(this.interceptorResponse.bind(this), this.interceptorResponseError.bind(this));

        this.invoice = new InvoiceController(this.$_axios);
        this.login = new LoginController(this.$_axios);
        this.pax = new PaxController(this.$_axios);
        this.payment = new PaymentController(this.$_axios);
        this.product = new ProductController(this.$_axios);
        this.productGroup = new ProductGroupController(this.$_axios);
        this.reservation = new ReservationController(this.$_axios);
        this.setting = new SettingController(this.$_axios);
        this.user = new UserController(this.$_axios);
    }

    private interceptorRequest(p_Config: AxiosRequestConfig): AxiosRequestConfig
    {
        let authToken: string = AccessToken.Get();

        if (authToken.length > 0)
        {
            p_Config.headers["Authorization"] = `Bearer ${ authToken }`;
        }

        p_Config.headers["License"] = this.licenseCode;
        return p_Config;
    }

    private interceptorResponse(response: AxiosResponse)
    {
        return response;
    }

    private interceptorResponseError(error: any)
    {
        let statusCode: number = error?.response?.status ?? 0;
        let errorCode: number = error?.response?.data?.ErrorCode ?? 0;
        let message: string = error?.response?.data?.Message ?? "";
        // let clientMessageEn: string = error?.response?.data?.ClientMessageEn ?? "";
        // let clientMessageFr: string = error?.response?.data?.ClientMessageFr ?? "";

        // Log error
        if (statusCode >= 500)
        {
            if (message.length > 0)
            {
                console.log(message); // eslint-disable-line
            }
            else
            {
                console.log(error); // eslint-disable-line
            }
        }

        // Custom error handling
        if ((errorCode === 40101) && (Store.state.configState.setting.license.authIsRequired))
        {
            Store.commit(new AuthenticationModalPayload.Mutations.SetIsAuth(false, LoginTypeEnum.None));
            if (AccessToken.Get().length > 0)
            {
                let payload = new ModalInformativePayload.Mutations.DisplayModalInformative(this.i18n(RezToursLanguageEnum.sessionTimeOutTitle), this.i18n(RezToursLanguageEnum.sessionTimeOutMsg));
                Store.commit(payload);
            }
            Store.commit(new AuthenticationModalPayload.Mutations.DisplayAuthenticationModal(0));
        }

        return Promise.reject(error);
    }

    private i18n(p_key: RezToursLanguageEnum, p_Param?: string): string
    {
        if (p_key)
        {
            let value: string = p_key.charAt(0).toUpperCase() + p_key.slice(1);
            if (p_Param)
            {
                let returnValue = Store.getters.getConfigLanguage.values[value] || "";
                return returnValue.replace("{0}", p_Param);
            }
            else
            {
                return Store.getters.getConfigLanguage.values[value] || "";
            }
        }
        return "";
    }
}

export default ApiManager;