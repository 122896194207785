import { ProductDisplayModeEnum } from "../_common/enums";
import LanguageModel from "../_common/languageModel";

//#region Query

export class ReservationQuery
{
    reservationCode: string;
    token: string;

    constructor(p_ReservationCode: string, p_Token: string)
    {
        this.reservationCode = p_ReservationCode;
        this.token = p_Token;
    }
}

export class ReservationBody
{
    busPickup: number = 0;
    dateDepart: string = "";
    dateReturn: string = "";
    listOption: ReservationBodyOption[] = [];
    nbAdult: number = 0;
    nbChild: number = 0;
    nbRoom: number = 0;
    product: string = "";
    pricingCategoryCode: number = 0;
    pricingClassCode: number = 0;
    productPriceCategoryCode: number = 0;
    productPriceClassCode: number = 0;
    productPriceDateCode: number = 0;
    project: string = "";
}

export class ReservationBodyOption
{
    code: number = 0;

    quantity: number = 0;
}

//#endregion

//#region Model

export class ReservationModel
{
    code: string = "";
    busPickup: ReservationBusPickupModel = new ReservationBusPickupModel();
    currency: string = "";
    dateDepart: string = "";
    dateReturn: string = "";
    deposit: number = 0;
    destination: ReservationDestinationModel = new ReservationDestinationModel();
    listOption: ReservationOptionModel[] = [];
    listPayment: ReservationPaymentModel[] = [];
    listProductGroup: ReservationProductGroupModel[] = [];
    listRoom: ReservationRoomModel[] = [];
    mainProduct: ReservationProductModel = new ReservationProductModel();
    origin: ReservationDestinationModel = new ReservationDestinationModel();
    productPriceDateCode: number = 0;
    total: number = 0;
    withTerms: boolean = false;
}

export class ReservationBusPickupModel
{
    code: number = 0;

    name: string = "";
    time: string = "";
}

export class ReservationDestinationModel
{
    code: string = "";

    name: LanguageModel = new LanguageModel();
}

export class ReservationOptionModel
{
    code: string = "";

    commission: number = 0;
    commissionTax1: number = 0;
    commissionTax2: number = 0;
    name: LanguageModel = new LanguageModel();
    price: number = 0;
    pricingClass: LanguageModel = new LanguageModel();
    pricingCategory: LanguageModel = new LanguageModel();
    quantity: number = 0;
    saleDeposit: number = 0;
    taxes: number = 0;
    total: number = 0;
}

export class ReservationPaymentModel
{
    index: number = 0;

    amount: number = 0;
    ccAuthorization: string = "";
    ccExpiration: string = "";
    ccNumber: string = "";
    issuer: string = "";
    status: number = 0;
}

export class ReservationProductGroupModel
{
    code: number = 0;

    name: LanguageModel = new LanguageModel();
}

export class ReservationRoomModel
{
    index: number = 0;

    listPax: ReservationRoomPaxModel[] = [];
}

export class ReservationRoomPaxModel
{
    index: number = 0;

    address: string = "";
    allergies: string = "";
    birthDate: string = "";
    city: string = "";
    email: string = "";
    firstName: string = "";
    gender: string = "";
    hasNoAllergies: boolean = false;
    hasNoMedicalCondition: boolean = false;
    lang: string = "EN";
    lastName: string = "";
    medicalCondition: string = "";
    postal: string = "";
    province: string = "";
    specialRequest: string = "";
    tel1: string = "";
    tel2: string = "";
    telExt: string = "";
    useSameAddress: boolean = false;
}

export class ReservationProductModel
{
    code: string ="";

    categoryAdult: ReservationProductCategoryModel = new ReservationProductCategoryModel();
    categoryChild: ReservationProductCategoryModel = new ReservationProductCategoryModel();
    dateFrom: string = "";
    dateTo: string = "";
    displayMode: ProductDisplayModeEnum = ProductDisplayModeEnum.Public;
    duration: number = 0;
    durationInNights: boolean = false;
    name: LanguageModel = new LanguageModel();
    pricingClass: ReservationProductPricingClassModel = new ReservationProductPricingClassModel();
}

export class ReservationProductCategoryModel
{
    commission: number = 0;
    commissionTax1: number = 0;
    commissionTax2: number = 0;
    price: number = 0;
    pricingCategory: ReservationProductPricingCategoryModel = new ReservationProductPricingCategoryModel();
    quantity: number = 0;
    saleDeposit: number = 0;
    taxes: number = 0;
    total: number = 0;
}

export class ReservationProductPricingClassModel
{
    code: number = 0;

    name: LanguageModel = new LanguageModel();
}

export class ReservationProductPricingCategoryModel
{
    code: number = 0;

    name: LanguageModel = new LanguageModel();
}

export class ReservationCreateModel
{
    reservationCode: string = "";
    token: string = "";
}

//#endregion