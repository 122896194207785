import { GetterTree, Getter } from "vuex";
import ConfigState from "./types";
import { RootState } from "@/store/types";
import { SettingLicenseLanguageModel } from "@/apiManager/setting/settingData";

export const getters: GetterTree<ConfigState, RootState> =
{
    getConfigLanguage(p_State: ConfigState): SettingLicenseLanguageModel
    {
        return (p_State.useMainLanguage ? p_State.setting.license.languages.lang1 : p_State.setting.license.languages.lang2);
    }
};