import LanguageModel from "../_common/languageModel";

//#region Query

export class InvoiceQuery
{
    file: string;

    constructor(p_File: string)
    {
        this.file = p_File;
    }
}

export class InvoicePrintURLQuery
{
    invoice: string;
    file: string;

    constructor(p_Invoice: string, p_File: string)
    {
        this.invoice = p_Invoice;
        this.file = p_File;
    }
}

export class InvoiceCreateBody
{
    isPaymentDisabled: boolean;
    lang: string = "EN";
    reservation: string;
    token: string;

    constructor(p_Reservation: string, p_Token: string, p_Lang: string, p_IsPaymentDisabled: boolean)
    {
        this.isPaymentDisabled = p_IsPaymentDisabled;
        this.lang = p_Lang;
        this.reservation = p_Reservation;
        this.token = p_Token;
    }
}

//#endregion

//#region Model

export class InvoiceSummaryModel
{
    file: string = "";

    balance: number = 0;
    currency: string = "";
    dateDepart: string = "";
    destination: LanguageModel = new LanguageModel();
}

export class InvoiceModel
{
    file: string = "";

    balance: number = 0;
    currency: string = "";
    dateDepart: string = "";
    dateReturn: string = "";
    destination: LanguageModel = new LanguageModel();
    invoice: string = "";
    listReceipt: InvoiceReceiptModel[] = [];
    total: number = 0;
}

export class InvoiceReceiptModel
{
    amount: number = 0;
    ccNumber: string = ""
    invoice: string = "";
    issuer: string = "";
}

export class InvoiceCreateModel
{
    file: string = "";
    invoice: string = "";
}

export class InvoicePrintURLModel
{
    url: string = "";
}

//#endregion