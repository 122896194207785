class LanguageSession
{
    static getUseMainLanguage(): boolean
    {
        if (LanguageSession.IsSupported())
        {
            let languageSelected: string = localStorage.getItem((process.env?.VUE_APP_LICENSE ?? "") + "_languageSelected") ?? "";
            if (languageSelected === "false")
            {
                return false;
            }
            else
            {
                return true;
            }
        }
        else
        {
            return true;
        }
    }

    static set(p_Value: boolean)
    {
        try
        {
            localStorage.setItem((process.env?.VUE_APP_LICENSE ?? "") + "_languageSelected", p_Value.toString());
        }
        catch
        {
        }
    }

    static IsSupported(): boolean
    {
        let test = "test";
        try
        {
            localStorage.setItem(test, test);
            localStorage.removeItem(test);
            return true;
        }
        catch(e)
        {
            return false;
        }
    }
}

export default LanguageSession;