import { MutationTree } from "vuex";
import AuthenticationModalState from "./types";
import MutationsEnum from "@/store/storeEnum/mutationsEnum";
import AuthenticationModalPayload from "./models";

export const mutations: MutationTree<AuthenticationModalState> =
{
    [MutationsEnum.DisplayAuthenticationModal](p_State: AuthenticationModalState, p_Payload: AuthenticationModalPayload.Mutations.DisplayAuthenticationModal)
    {
        p_State.sectionDisplayed = p_Payload.sectionDisplayed;
        p_State.display = true;
        p_State.callback = p_Payload.callback;
    },
    [MutationsEnum.HideAuthenticationModal](p_State: AuthenticationModalState, p_Payload: AuthenticationModalPayload.Mutations.HideAuthenticationModal)
    {
        p_State.callback?.();
        p_State.callback = null;
        p_State.display = false;
    },
    [MutationsEnum.SetIsAuth](p_State: AuthenticationModalState, p_Payload: AuthenticationModalPayload.Mutations.SetIsAuth)
    {
        p_State.isAuth = p_Payload.value;
        p_State.authType = p_Payload.authType;
    },
    [MutationsEnum.SetWelcomeComponent](p_State: AuthenticationModalState, p_Payload: AuthenticationModalPayload.Mutations.SetWelcomeComponent)
    {
        p_State.welcomeName = p_Payload.name;
        p_State.isShowWelcomeComponent = p_Payload.value;
    },
    [MutationsEnum.SetRedirectAfterRegistration](p_State: AuthenticationModalState, p_Payload: AuthenticationModalPayload.Mutations.SetRedirectAfterRegistration)
    {
        p_State.redirectAfterRegistration = p_Payload.value;
    },
    [MutationsEnum.SetVisibleCustomerProjectsModal](p_State: AuthenticationModalState, p_Payload: AuthenticationModalPayload.Mutations.SetVisibleCustomerProjectsModal)
    {
        p_State.isCustomerProjectsModalVisible = p_Payload.isVisible;
        p_State.listCustomerProjects = p_Payload.listCustomerProjects;
    }
};