
    import { Component, Vue } from "vue-property-decorator";

    @Component
    export default class App extends Vue
    {
        get getTheme(): string
        {
            return this.$tStore.state.configState.setting.license.theme;
        }
    }
