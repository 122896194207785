import Vue from "vue";
import Vuex from "vuex";
import { configState } from "./modules/config";
import { productState } from "./modules/product";
import { reservationState } from "./modules/reservation";
import { modalInformativeState } from "./modules/modalInformative";
import { authenticationModal } from "./modules/authenticationModal";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        configState,
        productState,
        reservationState,
        modalInformativeState,
        authenticationModal
    }
});

export default store;
