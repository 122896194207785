import Vue from "vue";
import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import ActionsEnum from "@/store/storeEnum/actionsEnum";
import ConfigState from "./types";
import ConfigPayload from "./models";
import { SettingModel } from "@/apiManager/setting/settingData";

export const actions: ActionTree<ConfigState, RootState> = {

    async [ActionsEnum.FetchSettings]({ commit }, p_Payload: ConfigPayload.Actions.FetchSettings)
    {
        try
        {
            commit(new ConfigPayload.Mutations.SetSettingsIsLoading(true));

            let setting: SettingModel = await Vue.$apiManager.setting.GetAsync();

            commit(new ConfigPayload.Mutations.SetSetting(setting));

            return setting;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
        finally
        {
            commit(new ConfigPayload.Mutations.SetSettingsIsLoading(false));
        }
    }
};