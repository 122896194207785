// Vue
import "./classComponentHooks";
import Vue from "vue";
import App from "./app/App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";

import "@/plugins/apiManagerPlugin";
import "@/plugins/tStoreMixinPlugin";
import "@/plugins/languageMixinPlugin";

// font-face + Icons
import "./icons";

// boostrap vue
import BootstrapVue from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";

// pattern 7-1
import "@/assets/sass/main.scss";

import { faValueAbsolute } from "@fortawesome/pro-solid-svg-icons";

Vue.config.productionTip = false;

Vue.use(BootstrapVue);

Vue.filter("formatMoney", (value: string, separator: string) =>
{
    return value.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, separator) ?? "";
});

Vue.filter("formatMoneyWithoutDigit", (value: number, separator: string) =>
{
    return Math.ceil(value).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, separator);
});

Vue.filter("dateFormatting", (value: string, dateFormat: string) =>
{
    let date = moment(value);
    if (date.isValid())
    {
        return date.format(dateFormat);
    }
    else
    {
        return "";
    }
});

new Vue({
    router,
    store,
    render: (h) =>
    {
        return h(App);
    }
}).$mount("#app");

