import Vue from "vue";
import Component from "vue-class-component";
import { RootState } from "@/store/types";
import { Store } from "vuex";

@Component
export default class TStoreMixin extends Vue
{

    get $tStore(): Store<RootState>
    {
        return this.$store;
    }

}