import { Module } from "vuex";
import { mutations } from "./mutations";
import { RootState } from "@/store/types";
import AuthenticationModalState from "./types";
import AccessToken from "@/models/accessToken";
import { LoginTypeEnum } from "@/apiManager/login/loginData";

export const state: AuthenticationModalState =
{
    display: false,
    sectionDisplayed: 0,
    callback: null,
    isAuth: AccessToken.Get().length > 0,
    isShowWelcomeComponent: false,
    welcomeName: "",
    authType: LoginTypeEnum.None,
    redirectAfterRegistration: "",
    isCustomerProjectsModalVisible: false,
    listCustomerProjects: []
};

const namespaced: boolean = false;

export const authenticationModal: Module<AuthenticationModalState, RootState> = {
    namespaced,
    state,
    mutations
};