enum RoutesEnum
{
    Package = "package",
    // Hotel = "hotel",
    // Flight = "flight",
    ProductDetail = "productDetail",
    TravelBag = "travelBag",
    PaxInfo = "paxInfo",
    Payment = "payment",
    NotFound = "notFound",
    Booking = "booking",
    Redirect = "redirect",
    Profile = "profile",
    Inscription = "inscription",
    RegisterCustomer = "registerCustomer",
    OutstandingInvoiceList = "outstandingInvoiceList",
    OutstandingInvoiceDetail = "outstandingInvoiceDetail",
    Interface = "interface",
    ResetPassword = "resetPassword",
    PaymentOnline = "paymentOnline",
    Agreement = "Agreement",
    Options = "Options",
    AutoLogin = "AutoLogin"
}

export enum RoutesReservationEnum
{
    TravelBag = RoutesEnum.TravelBag,
    PaxInfo = RoutesEnum.PaxInfo,
    Payment = RoutesEnum.Payment,
    Redirect = RoutesEnum.Redirect
}

export default RoutesEnum;