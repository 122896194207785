
    import { Component, Prop, PropSync, Watch, Vue } from "vue-property-decorator";
    import ProductPayload from "@/store/modules/product/models";
    import PopupProductDatesItem from "./PopupProductDatesItem.vue";
    import { ProductDateModel, ProductDetailModel } from "@/apiManager/product/productData";
    import LanguageModel from "@/apiManager/_common/languageModel";

    @Component
    export default class PopupProductDates extends Vue
    {
        get components()
        {
            let components =
                {
                    "popup-product-dates-item": PopupProductDatesItem
                };
            return components;
        }

        @Prop({ type: String, required: true }) private readonly productCode!: string;
        @Prop({ type: Object, required: true }) private readonly productName!: LanguageModel;
        @PropSync("open", { type: Boolean }) private syncedOpen!: boolean;

        @Watch("open")
        onOpenChange(value: boolean)
        {
            if (!value)
            {
                this.$tStore.commit(new ProductPayload.Mutations.SetProductDates([]));
            }
            else
            {
                let payload = new ProductPayload.Actions.FetchProductDates(this.productCode);

                this.$tStore.dispatch(payload);
            }
        }

        get getMonthsNames(): string[]
        {
            return this.$tStore.getters.getConfigLanguage.months_Names;
        }

        get getTheme(): string
        {
            return this.$tStore.state.configState.setting.license.theme;
        }

        get getProductDates(): ProductDateModel[]
        {
            return this.$tStore.state.productState.productDates;
        }

        get getProductDatesIsLoading(): boolean
        {
            return this.$tStore.state.productState.productDatesIsLoading;
        }
    }
