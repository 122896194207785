
    import { Component, Vue } from "vue-property-decorator";
    import LoginBox from "./LoginBox.vue";
    import ForgottenPassword from "./ForgottenPassword.vue";
    import ConfirmationPassword from "./ConfirmationPassword.vue";
    import AuthenticationModalPayload from "../../../../store/modules/authenticationModal/models";
    import RoutesEnum from "@/router/routesEnum";

    @Component
    export default class AuthModal extends Vue
    {
        get display(): boolean
        {
            return this.$tStore.state.authenticationModal.display;
        }
        set display(value: boolean)
        {
            if (!value)
            {
                this.$tStore.commit(
                    new AuthenticationModalPayload.Mutations.HideAuthenticationModal()
                );
            }
        }

        sectionDisplayed: number = 0;

        get components()
        {
            let components = {
                "login-box": LoginBox,
                "forgotten-password": ForgottenPassword,
                "confirmation-password": ConfirmationPassword
            };

            return components;
        }

        get getTheme(): string
        {
            return this.$tStore.state.configState.setting.license.theme;
        }

        get getShowInscription(): boolean
        {
            return !this.$tStore.state.configState.setting.license.disableB2C;
        }

        onClickInscription()
        {
            this.$tStore.commit(new AuthenticationModalPayload.Mutations.SetRedirectAfterRegistration(this.$route.fullPath));
            this.$router.push({name: RoutesEnum.Inscription });
            this.$tStore.commit(new AuthenticationModalPayload.Mutations.HideAuthenticationModal());
        }
    }
