import Vue from "vue";
import PluginModel from "./pluginModel";
import ApiManager from "@/apiManager";

const initApiManager = new ApiManager();

let plugin: PluginModel = {
    install: function(Vue, options)
    {
        Vue.$apiManager = initApiManager;
        Object.defineProperties(Vue.prototype, {
            $apiManager: {
                get()
                {
                    return initApiManager;
                }
            }
        });
    }
};

Vue.use(plugin);

export default plugin;