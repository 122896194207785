import LanguageModel from "../_common/languageModel";

//#region Query

export class SettingTermsQuery
{
    lang: string;

    constructor(p_Lang: string)
    {
        this.lang = p_Lang;
    }
}

//#endregion

//#region Model

export class SettingModel
{
    agency: SettingAgencyModel = new SettingAgencyModel();
    license: SettingLicenseModel = new SettingLicenseModel();
    listMenu: SettingMenuModel[] = [];
    listSlider: SettingSliderModel[] = [];
    socialNetwork: SettingSocialNetworkModel = new SettingSocialNetworkModel();
}

export class SettingAgencyModel
{
    address: LanguageModel = new LanguageModel();
    detail: LanguageModel = new LanguageModel();
    lang1: SettingAgencyLanguageModel = new SettingAgencyLanguageModel();
    lang2: SettingAgencyLanguageModel = new SettingAgencyLanguageModel();
    logo: LanguageModel = new LanguageModel();
    email: LanguageModel = new LanguageModel();
    homeMessage: LanguageModel = new LanguageModel();
    name: LanguageModel = new LanguageModel();
    telephone: LanguageModel = new LanguageModel();
    webSite: LanguageModel = new LanguageModel();
}

export class SettingAgencyLanguageModel
{
    code: string = "";

    name: string = "";
}

export class SettingLicenseModel
{
    authIsRequired: boolean = false;
    dateFormat: string = "";
    depositAccepted: boolean = false;
    disableB2C: boolean = false;
    disablePaymentAgent: boolean = false;
    languages: SettingLicenseLanguagesModel = new SettingLicenseLanguagesModel();
    productGroupAppearance: number = 0;
    showCommission: boolean = false;
    theme: string = "";
    title: string = "";
    testMode: boolean = false;
    urlWebSiteClient: string = "";
    urlWebSiteServer: string = "";
}

export class SettingLicenseLanguagesModel
{
    english: SettingLicenseLanguageModel = new SettingLicenseLanguageModel();
    french: SettingLicenseLanguageModel = new SettingLicenseLanguageModel();
    lang1: SettingLicenseLanguageModel = new SettingLicenseLanguageModel();
    lang2: SettingLicenseLanguageModel = new SettingLicenseLanguageModel();
}

export class SettingLicenseLanguageModel
{
    currencyPosition: number = 0;
    days_Abbr: string[] = [];
    days_Names: string[] = [];
    months_Abbr: string[] = [];
    months_Names: string[] = [];
    values: Record<string, string> = {}
}

export class SettingMenuModel
{
    languages: SettingMenuLanguagesModel = new SettingMenuLanguagesModel();
}

export class SettingMenuLanguagesModel
{
    english: SettingMenuLanguageModel = new SettingMenuLanguageModel();
    french: SettingMenuLanguageModel = new SettingMenuLanguageModel();
    lang1: SettingMenuLanguageModel = new SettingMenuLanguageModel();
    lang2: SettingMenuLanguageModel = new SettingMenuLanguageModel();
}

export class SettingMenuLanguageModel
{
    redirect: boolean = false;
    text: string = "";
    url: string = "";
}

export class SettingSliderModel
{
    languages: SettingSliderLanguagesModel = new SettingSliderLanguagesModel();
    pathImage: string = "";
}

export class SettingSliderLanguagesModel
{
    english: SettingSliderLanguageModel = new SettingSliderLanguageModel();
    french: SettingSliderLanguageModel = new SettingSliderLanguageModel();
    lang1: SettingSliderLanguageModel = new SettingSliderLanguageModel();
    lang2: SettingSliderLanguageModel = new SettingSliderLanguageModel();
}

export class SettingSliderLanguageModel
{
    description: string = "";
    link: SettingSliderLinkModel = new SettingSliderLinkModel();
    title: string = "";
}

export class SettingSliderLinkModel
{
    enabled: boolean = false;
    redirect: boolean = false;
    text: string = "";
    url: string = "";
}

export class SettingSocialNetworkModel
{
    urlFacebook: LanguageModel = new LanguageModel();
    urlInstagram: LanguageModel = new LanguageModel();
    urlPinterest: LanguageModel = new LanguageModel();
    urlTwitter: LanguageModel = new LanguageModel();
}

export class SettingTermsModel
{
    terms: string = "";
}

//#endregion