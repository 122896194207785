import { AxiosInstance } from "axios";
import ApiController from "../_common/apiController";
import { PaxUpdateBody } from "./paxData";

class PaxController extends ApiController
{
    constructor(p_Axios: AxiosInstance)
    {
        super(p_Axios, "pax", "1.0");
    }

    public async updateListPaxAsync(p_Body: PaxUpdateBody): Promise<boolean>
    {
        try
        {
            await this.$axios.put(this.$controllerUrl, p_Body);
            return true;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }
}

export default PaxController;