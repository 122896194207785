//#region Query

export class LoginSignInBody
{
    login: string;
    password: string;

    constructor(p_Login: string, p_Password: string)
    {
        this.login = p_Login;
        this.password = p_Password;
    }
}

export class LoginRegisterPaxBody
{
    email: string = "";

    firstName: string = "";
    lang: string = "EN";
    lastName: string = "";
    password: string = "";
    telephone: string = "";
}

export class LoginRegisterCustomerBody
{
    email: string = "";

    password: string = "";
    token: string = "";

    constructor(p_Email: string, p_Password: string, p_Token: string)
    {
        this.email = p_Email;
        this.password = p_Password;
        this.token = p_Token;
    }
}

export class LoginSendResetPasswordEmailBody
{
    email: string = "";
    lang: string = "";

    constructor(p_Email: string, p_Lang: string)
    {
        this.email = p_Email;
        this.lang = p_Lang;
    }
}

export class LoginSetResetPasswordBody
{
    newPassword: string = "";
    token: string = "";

    constructor(p_NewPassword: string, p_Token: string)
    {
        this.newPassword = p_NewPassword;
        this.token = p_Token;
    }
}

export class LoginAutoLoginBody
{
    token: string;

    constructor(p_Token: string)
    {
        this.token = p_Token;
    }
}

//#endregion

//#region Model

export class LoginSignInModel
{
    token: string = "";
}

export enum LoginTypeEnum
{
    None = 0,
    Pax = 1,
    CorporateAccount = 2,
    Agent = 3,
}

//#endregion