import { Payload } from "vuex";
import ActionsEnum from "@/store/storeEnum/actionsEnum";
import MutationsEnum from "@/store/storeEnum/mutationsEnum";
import { SettingModel } from "@/apiManager/setting/settingData";

namespace ConfigPayload
{
    export namespace Actions
    {
        export class FetchSettings implements Payload
        {
            public readonly type: string = ActionsEnum.FetchSettings;
        }
    }

    export namespace Mutations
    {
        export class SetSetting implements Payload
        {
            public readonly type: string = MutationsEnum.SetSetting;
            public setting: SettingModel;

            constructor(p_Setting: SettingModel)
            {
                this.setting = p_Setting;
            }
        }

        export class SetSettingsIsLoading implements Payload
        {
            public readonly type: string = MutationsEnum.SetSettingsIsLoading;
            public isLoading: boolean;

            constructor(p_IsLoading: boolean)
            {
                this.isLoading = p_IsLoading;
            }
        }

        export class SetSettingUseMainLanguage implements Payload
        {
            public readonly type: string = MutationsEnum.SetSettingUseMainLanguage;
            public useMainLanguage: boolean;

            constructor(p_UseMainLanguage: boolean)
            {
                this.useMainLanguage = p_UseMainLanguage;
            }
        }
    }
}

export default ConfigPayload;