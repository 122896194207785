import { AxiosInstance, AxiosRequestConfig } from "axios";
import ApiController from "../_common/apiController";
import { PaymentRedirectReservationQuery, PaymentRedirectInvoiceQuery, PaymentURLModel, PaymentCancelBody } from "./paymentData";

class PaymentController extends ApiController
{
    constructor(p_Axios: AxiosInstance)
    {
        super(p_Axios, "payment", "1.0");
    }

    public async getURLReservationAsync(p_Query: PaymentRedirectReservationQuery): Promise<PaymentURLModel>
    {
        try
        {
            let config: AxiosRequestConfig = {
                params: p_Query
            };

            const request = await this.$axios.get<PaymentURLModel>(`${ this.$controllerUrl }/url/reservation/${ p_Query.reservation }`, config);
            return request.data;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }

    public async getURLInvoiceAsync(p_Query: PaymentRedirectInvoiceQuery): Promise<PaymentURLModel>
    {
        try
        {
            let config: AxiosRequestConfig = {
                params: p_Query
            };

            const request = await this.$axios.get<PaymentURLModel>(`${ this.$controllerUrl }/url/invoice/${ p_Query.invoice }`, config);
            return request.data;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }

    public async cancelAsync(p_Body: PaymentCancelBody): Promise<void>
    {
        try
        {
            await this.$axios.post(`${ this.$controllerUrl }/cancel`, p_Body);
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }
}

export default PaymentController;