import { AxiosInstance, AxiosError, AxiosRequestConfig } from "axios";
import store from "@/store";
import AccessToken from "@/models/accessToken";
import AuthenticationModalPayload from "@/store/modules/authenticationModal/models";
import ModalInformativePayload from "@/store/modules/modalInformative/models";
import { LoginTypeEnum } from "./login/loginData";
import { RezToursLanguageEnum } from "./_common/enums";

class AuthenticationProxy
{
    static axiosInstance: AxiosInstance;
    static authIsRequired: boolean = false;

    static async RequestWithAuthInHeader<TResult>(p_Request: Function): Promise<TResult>
    {
        try
        {
            let config: AxiosRequestConfig = AuthenticationProxy.SetConfig();
            return await AuthenticationProxy.RequestWithAuth<TResult>(p_Request, config);
        }
        catch(ex)
        {
            throw ex;
        }
    }

    static async RequestWithAuthInQuery<TResult>(p_Request: Function): Promise<TResult>
    {
        try
        {
            let config: AxiosRequestConfig = AuthenticationProxy.SetConfig(true);
            return await AuthenticationProxy.RequestWithAuth<TResult>(p_Request, config, true);
        }
        catch(ex)
        {
            throw ex;
        }
    }

    static SetConfig(p_IsInQuery: boolean = false): AxiosRequestConfig
    {
        let config: AxiosRequestConfig = {};
        if (store.state.configState.setting.license.authIsRequired)
        {
            let accessToken: string = AccessToken.Get();
            if (accessToken.length > 0)
            {
                if (p_IsInQuery)
                {
                    config.params = {"Authorization": accessToken };
                }
                else
                {
                    config.headers = {"Authorization": "Bearer " + accessToken };
                }
            }
        }
        return config;
    }

    static async RequestWithAuth<TResult>(p_Request: Function, p_Config: AxiosRequestConfig, p_IsInQuery: boolean = false): Promise<TResult>
    {
        try
        {
            return await p_Request(p_Config);
        }
        catch(ex)
        {

            if ((ex as AxiosError)?.response?.status === 401 && store.state.configState.setting.license.authIsRequired)
            {
                store.commit(new AuthenticationModalPayload.Mutations.SetIsAuth(false, LoginTypeEnum.None));
                if (AccessToken.Get().length > 0)
                {
                    let payload = new ModalInformativePayload.Mutations.DisplayModalInformative(this.i18n(RezToursLanguageEnum.sessionTimeOutTitle), this.i18n(RezToursLanguageEnum.sessionTimeOutMsg));
                    store.commit(payload);
                }
                store.commit(new AuthenticationModalPayload.Mutations.DisplayAuthenticationModal(0));
            }

            throw ex;
        }
    }

    static i18n(p_key: RezToursLanguageEnum, p_Param?: string): string
    {
        if (p_key)
        {
            let value: string = p_key.charAt(0).toUpperCase() + p_key.slice(1);
            if (p_Param)
            {
                let returnValue = store.getters.getConfigLanguage.values[value] || "";
                return returnValue.replace("{0}", p_Param);
            }
            else
            {
                return store.getters.getConfigLanguage.values[value] || "";
            }
        }
        return "";
    }

}

export default AuthenticationProxy;