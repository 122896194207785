
    import { Component, Vue } from "vue-property-decorator";
    import ProductGroup from "./ProductGroup.vue";
    import ProductPayload from "@/store/modules/product/models";
    import { ProductGroupListModel } from "@/apiManager/productGroup/productGroupData";

    @Component
    export default class ProductList extends Vue
    {
        get components()
        {
            let components =
                {
                    "product-group": ProductGroup
                };
            return components;
        }

        dateFrom: string = "";
        duration: string = "";
        origin: string = "";
        destination: string = "";

        get getProductGroups(): ProductGroupListModel[]
        {
            return this.$tStore.state.productState.productGroups;
        }

        get getProductGroupsIsLoading(): boolean
        {
            return this.$tStore.state.productState.productGroupsIsLoading;
        }

        get getProductGroupEmtpyMessage(): string
        {
            if (!this.getProductGroupsIsLoading && this.getProductGroups.length === 0)
            {
                return this.i18n(this.getRezToursLanguageEnum.noProductsAvailable);
            }

            return "";
        }

        mounted()
        {
            this.productGroupFetch();
        }

        async productGroupFetch()
        {
            try
            {
                await this.$tStore.dispatch(new ProductPayload.Actions.FetchProducts());
            }
            catch
            {
            }
        }
    }
